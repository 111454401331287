import React, { FunctionComponent, PropsWithChildren } from 'react';
import { useTabContext } from '@mui/lab';
import { Box } from '@mui/material';

interface TabProps {
  value: string;
}

const TabPanel: FunctionComponent<PropsWithChildren<TabProps>> = ({
  children,
  value,
}) => {
  const { value: contextValue } = useTabContext() || {};
  return (
    <Box
      sx={{ display: value === contextValue ? 'block' : 'none' }}
      key={value}>
      {children}
    </Box>
  );
};

export default TabPanel;
