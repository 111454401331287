import { ReactElement, useState } from 'react';
import { IconButton, Typography, Menu, MenuItem } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

type CustomExportButtonProps = {
  handleExportClick: (format: string) => void;
};

const CustomExportButton = (props: CustomExportButtonProps): ReactElement => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleFormatClick = (format: string) => {
    handleMenuClose();
    props.handleExportClick(format);
  };

  return (
    <>
      <IconButton
        onClick={handleMenuOpen}
        color="info"
        size="small"
        aria-label="Filter">
        <FileDownloadOutlinedIcon />
        <Typography variant="body2" style={{ marginLeft: 4 }}>
          Export
        </Typography>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}>
        <MenuItem onClick={() => handleFormatClick('csv')}>CSV</MenuItem>
        <MenuItem onClick={() => handleFormatClick('json')}>JSON</MenuItem>
      </Menu>
    </>
  );
};

export default CustomExportButton;
