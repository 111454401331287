import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

type Site = {
  siteId: string;
  name: string;
};

type Device = {
  deviceId: string;
  name: string;
  siteId: string;
  connectionProfiles: { protocol: string }[];
};

type TreeChartProps = {
  sites: Site[];
  devices: Device[];
  themeMode: 'light' | 'dark';
};

const TreeChart: React.FC<TreeChartProps> = ({ sites, devices, themeMode }) => {
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const textColor = themeMode === 'light' ? '#000000' : '#FFFFFF';
    const hierarchyData = {
      name: 'sites',
      children: sites.map((site) => {
        const siteDevices = devices.filter(
          (device) => device.siteId === site.siteId,
        );

        const protocolMap = new Map<
          string,
          { name: string; children: { name: string; value: number }[] }
        >();

        siteDevices.forEach((device) => {
          device.connectionProfiles.forEach((profile) => {
            const protocolName = profile.protocol;
            if (!protocolMap.has(protocolName)) {
              protocolMap.set(protocolName, {
                name: protocolName,
                children: [],
              });
            }

            // Add the device to the corresponding protocol group
            protocolMap
              .get(protocolName)!
              .children.push({ name: device.name, value: 1 });
          });
        });

        // Convert the map to an array of children
        const protocolChildren = Array.from(protocolMap.values());

        return {
          name: site.name,
          children: protocolChildren,
        };
      }),
    };

    // Remove any existing SVG
    d3.select(chartRef.current).select('svg').remove();

    const width = chartRef.current?.clientWidth || 800;

    const dx = 20;
    const dy = width / 5; // Adjust spacing based on width

    const root: any = d3.hierarchy(hierarchyData);
    const tree = d3.tree().nodeSize([dx, dy]); // Create a tree layout
    tree(root);

    // Find bounds for x-axis adjustment
    let x0 = Infinity;
    let x1 = -x0;
    root.each((d: any) => {
      if (d.x > x1) x1 = d.x;
      if (d.x < x0) x0 = d.x;
    });

    const adjustedHeight = x1 - x0 + dx * 2;
    const svg = d3
      .select(chartRef.current)
      .append('svg')
      .attr('width', width)
      .attr('height', adjustedHeight)
      .attr('viewBox', [-dy / 3, x0 - dx, width, adjustedHeight])
      .attr('style', 'max-width: 100%; height: 100%; font: 10px sans-serif;');

    // Draw links (connecting lines)
    svg
      .append('g')
      .attr('fill', 'none')
      .attr('stroke', '#ccc')
      .attr('stroke-opacity', 0.8)
      .attr('stroke-width', 1.5)
      .selectAll('path')
      .data(root.links())
      .join('path')
      .attr(
        'd',
        (d3 as any)
          .linkHorizontal()
          .x((d: any) => d.y)
          .y((d: any) => d.x),
      );

    // Draw nodes (circles and labels)
    const node = svg
      .append('g')
      .selectAll('g')
      .data(root.descendants())
      .join('g')
      .attr('transform', (d: any) => `translate(${d.y},${d.x})`);

    node
      .append('circle')
      .attr('fill', (d: any) => (d.children ? '#555' : '#999'))
      .attr('r', 4);

    node
      .append('text')
      .attr('dy', '0.31em')
      .attr('x', (d: any) => (d.children ? -8 : 8))
      .attr('text-anchor', (d: any) => (d.children ? 'end' : 'start'))
      .text((d: any) => d.data.name)
      .style('fill', textColor)
      .clone(true)
      .lower();

    // Make the chart responsive
    const resizeObserver = new ResizeObserver(() => {
      const newWidth = chartRef.current?.clientWidth || 800;
      svg.attr('width', newWidth);
    });
    resizeObserver.observe(chartRef.current!);

    return () => {
      resizeObserver.disconnect();
    };
  }, [sites, devices, themeMode]);

  return <div ref={chartRef} style={{ width: '100%', height: '100%' }}></div>;
};

export default TreeChart;
