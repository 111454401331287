import React, { useCallback, useState, useEffect } from 'react';

import GroupAdd from '@mui/icons-material/GroupAdd';
import {
  CssBaseline,
  Card,
  Link,
  Typography,
  Button,
  CardHeader,
  Box,
} from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import apiClient from 'common/apiClientAxios';
import UserGroupsTable from './UserGroupsTable';
import DeleteGroup from './DeleteUserGroup';
import { setLoader, setSnackbarToast } from 'redux/UiStateSlice';
import { useContentStyles } from 'common/styles/useContentStyles';
import { UserGroup } from '../types';
import {
  getAccessWindowDisplaySetting,
  hasPermission,
  isEndUser,
} from 'common/helpers/utils';
import { constants } from 'common/constants';
import { RootState } from 'redux/Store';

const UserGroups: React.FC = () => {
  const classes = useContentStyles();
  const dispatch = useDispatch();
  const [isUpdate, setUpdate] = useState(true);
  const [selectedGroup, setSelectedGroup] = useState<UserGroup>({
    groupId: '',
    name: '',
    usersCount: 0,
  });
  const [openDialogue, setOpenDialogue] = React.useState(false);

  const [groups, setGroups] = useState<UserGroup[]>([]);
  const navigate = useNavigate();
  const updateTable = useCallback(async () => {
    try {
      const groupsResponse = await apiClient.get('/users-groups');
      setGroups(groupsResponse.data.data as UserGroup[]);
    } catch (error: any) {
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: 'error',
        }),
      );
    }
  }, [dispatch]);

  useEffect(() => {
    if (isUpdate) {
      updateTable();
      setUpdate(false);
    }
  }, [isUpdate, updateTable]);

  const openAddGroupForm = () => {
    navigate(`/portal/users/summary`, { state: { isAddUserGroup: true } });
  };

  const onDeleteGroup = async () => {
    try {
      if (selectedGroup.groupId) {
        const groupDeleteResponse = await apiClient.delete(
          `/users-groups/${selectedGroup.groupId}`,
        );
        dispatch(
          setSnackbarToast({
            message: groupDeleteResponse.data.meta.message,
            open: true,
            severity: 'success',
          }),
        );
        onDeleteUpdateTable();
      }
    } catch (error: any) {
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: 'error',
        }),
      );
      setOpenDialogue(false);
    }
  };

  const handleDeleteGroup = (groupObj: UserGroup) => {
    setSelectedGroup(groupObj);
    setOpenDialogue(true);
  };

  const onDeleteUpdateTable = () => {
    setOpenDialogue(false);
    setUpdate(true);
  };

  const onCancelRemove = () => {
    setOpenDialogue(false);
  };

  const handleFavoriteGroup = async (group: UserGroup) => {
    try {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        }),
      );
      if (group.isFavorite) {
        await apiClient.delete(`users/favoriteUserGroup/${group.groupId}`);
      } else {
        await apiClient.put(`users/favoriteUserGroup/${group.groupId}`);
      }
      const groupsResponse = await apiClient.get('/users-groups');
      setGroups(groupsResponse.data.data as UserGroup[]);
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        }),
      );
    } catch (error: any) {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        }),
      );
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: 'error',
        }),
      );
    }
  };
  const { userData } = useSelector((state: RootState) => state.userState);
  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.contentPadding}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="none" color="inherit">
            Users
          </Link>
          <Typography variant="body1">{'Groups'}</Typography>
        </Breadcrumbs>
      </div>
      <Card elevation={0} className={classes.contentSection}>
        <CardHeader
          disableTypography
          title="Users Groups"
          className={classes.cardHeader}
          action={
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: (theme) => theme.palette.info.main,
              }}>
              <Box
                sx={{
                  paddingRight: 2,
                  marginRight: 2,
                }}>
                <Typography>
                  Times displayed in:{' '}
                  <strong>
                    {
                      getAccessWindowDisplaySetting(
                        userData.accessWindowDisplay,
                      ).display
                    }
                  </strong>
                </Typography>
              </Box>
              {!isEndUser() && hasPermission('users.groups', 'write') && (
                <Button
                  onClick={openAddGroupForm}
                  color="info"
                  startIcon={<GroupAdd />}>
                  {'Create Group'}
                </Button>
              )}
            </Box>
          }></CardHeader>
        {openDialogue && (
          <DeleteGroup
            onDeleteGroup={onDeleteGroup}
            selectedGroup={selectedGroup}
            open={openDialogue}
            onCancelRemove={onCancelRemove}></DeleteGroup>
        )}
        <UserGroupsTable
          data={groups}
          handleDeleteGroup={handleDeleteGroup}
          handleFavoriteGroup={handleFavoriteGroup}></UserGroupsTable>
      </Card>
    </React.Fragment>
  );
};

export default UserGroups;
