import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import GroupAdd from '@mui/icons-material/GroupAdd';
import CssBaseline from '@mui/material/CssBaseline';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/Store';

import { constants } from '../../common/constants';
import { UserFormMasterData, UserForm, UserGroup } from './types';
import { LoginFeature, UserGroupType, UserStatus } from 'common/enums';
import apiClient from 'common/apiClientAxios';
import { setLoader, setSnackbarToast } from 'redux/UiStateSlice';
import { userFormDefaultValues } from './Users';
import { useDrawerFormStyles } from 'common/styles/useDrawerFormStyles';
import TcTextField from 'common/components/TcTextField';
import TcSelectDropdown from 'common/components/TcSelectDropdown';
import { State } from 'pages/sites/types';
import TcAutocomplete from 'common/components/TcAutocomplete';
import FormHeader from 'common/components/FormHeader';
import TcPasswordField from 'common/components/TcPasswordField';
import SelectGroupDialog from './SelectGroupDialog';
import { filterUserGroups, hasPermission } from 'common/helpers/utils';
import TcSelectWithButtonLastOption from 'common/components/TcSelectWithButtonLastOption';

type AddUserProps = {
  onClose: () => void;
  onSaveUpdateTable: () => void;
  userFormMasterData: UserFormMasterData;
  openAddOrgForm: () => void;
};

const AddUser: React.FC<AddUserProps> = (addUserProps) => {
  const classes = useDrawerFormStyles();
  const dispatch = useDispatch();
  const { groups, organizations, countries, roles } =
    addUserProps.userFormMasterData;
  const onClose = () => {
    addUserProps.onClose();
  };
  const methods = useForm<UserForm>({
    defaultValues: userFormDefaultValues,
    mode: 'onBlur',
  });
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { isValid },
  } = methods;
  const [states, setStates] = useState<State[]>([]);
  const [selectedState, setSelectedState] = useState<State>({
    stateId: '',
    code: '',
    countryId: '',
    countryName: '',
    name: '',
  });

  const watchedCountryValue = watch('countryId');
  const onStateSelect = (_event: any, value: State | null) => {
    if (value) {
      setSelectedState(value);
      setValue('stateId', value.stateId, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  };

  useEffect(() => {
    const fetchStates = async () => {
      if (watchedCountryValue) {
        setSelectedState({
          stateId: '',
          code: '',
          countryId: '',
          countryName: '',
          name: '',
        });
        setValue('stateId', '', {
          shouldValidate: true,
          shouldDirty: true,
        });
        dispatch(
          setLoader({
            loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
            openLoader: true,
          }),
        );
        try {
          const response = await apiClient.get(
            `/countries/${watchedCountryValue}/states`,
          );
          setStates(response.data.data);
          dispatch(
            setLoader({
              loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
              openLoader: false,
            }),
          );
        } catch (error: any) {
          dispatch(
            setLoader({
              loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
              openLoader: false,
            }),
          );
          const errorData =
            error.response?.data?.meta?.message || String(error.message);
          dispatch(
            setSnackbarToast({
              message: errorData,
              open: true,
              severity: 'error',
            }),
          );
        }
      }
    };
    fetchStates();
  }, [dispatch, setValue, watchedCountryValue]);

  const [affectedDynamicGroups, setAffectedDynamicGroups] = useState<
    UserGroup[]
  >([]);
  const [submittedUserFormData, setSubmittedUserFormData] = useState<UserForm>({
    ...userFormDefaultValues,
  });
  const [openSelectGroupDialog, setOpenSelectGroupDialog] = useState(false);
  const { availableLogins } = useSelector(
    (state: RootState) => state.loginSettingsState,
  );

  const onSaveUser = handleSubmit(async (data: UserForm) => {
    setSubmittedUserFormData(data);
    const dynamicGroups = groups.filter(
      (group) => group.type === UserGroupType.DYNAMIC,
    );
    const filterGroups = filterUserGroups(dynamicGroups, data);
    setAffectedDynamicGroups(filterGroups);
    if (filterGroups.length === 0) {
      submitUser([], data);
    } else {
      setOpenSelectGroupDialog(true);
    }
  });

  const submitUser = async (selectedGroups?: string[], data?: UserForm) => {
    try {
      const formData = data ?? submittedUserFormData;
      const userGroups = groups
        .filter((group) => selectedGroups?.includes(group.groupId))
        .map((obj) => {
          return { groupId: obj.groupId, name: obj.name };
        });
      const roleName = roles.find(
        (thisRole) => thisRole.roleId === formData.roleId,
      )?.name;
      const country = countries.find(
        (thisCountry) => thisCountry.countryId === formData.countryId,
      )?.name;
      //org changes
      const organization = organizations.find(
        (thisOrg) => thisOrg.organizationId === formData.organizationId,
      );

      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        }),
      );
      const userResponse = await apiClient.post(`/users`, {
        ...formData,
        groups: userGroups,
        country,
        state: selectedState.name,
        roleName,
        organization: organization && {
          organizationId: organization?.organizationId,
          name: organization?.name,
        },
        password: availableLogins.includes(LoginFeature.CUSTOM)
          ? formData.password
          : constants.AZURE_USER_DEFAULT_PASSWORD,
        username: availableLogins.includes(LoginFeature.CUSTOM)
          ? formData.username
          : formData.email,
      });
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        }),
      );
      dispatch(
        setSnackbarToast({
          message: userResponse.data.meta.message,
          open: true,
          severity: 'success',
        }),
      );
    } catch (error: any) {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        }),
      );
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: 'error',
        }),
      );
    }
    addUserProps.onSaveUpdateTable();
  };

  const handleCreateOrganization = () => {
    addUserProps.openAddOrgForm();
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <FormHeader title={`Add User`} onClose={onClose}></FormHeader>
      <FormProvider {...methods}>
        <form noValidate autoComplete="off">
          <Grid container spacing={2} className={classes.formContainer}>
            <Grid item xs={6}>
              <TcTextField
                name="email"
                label="Email*"
                rules={{
                  required: {
                    value: true,
                    message: 'Email Address required',
                  },
                  pattern: {
                    value: constants.EMAIL_REGEX,
                    message: 'Invalid Email Address',
                  },
                }}></TcTextField>
            </Grid>
            <Grid item xs={6}>
              <TcTextField
                name="firstName"
                label="First Name*"
                rules={{
                  required: {
                    value: true,
                    message: 'First name is required',
                  },
                  pattern: {
                    value: constants.USER_NAME_REGEX,
                    message: 'Invalid First name',
                  },
                }}></TcTextField>
            </Grid>
            <Grid item xs={6}>
              <TcTextField
                name="lastName"
                label="Last Name*"
                rules={{
                  required: {
                    value: true,
                    message: 'Last name is required',
                  },
                  pattern: {
                    value: constants.USER_NAME_REGEX,
                    message: 'Invalid Last name',
                  },
                }}></TcTextField>
            </Grid>
            <Grid item xs={6}>
              <TcTextField
                name="middleName"
                label="Middle Name"
                rules={{
                  pattern: {
                    value: constants.USER_NAME_REGEX,
                    message: 'Invalid Middle name',
                  },
                }}
              />
            </Grid>
            {availableLogins.includes(LoginFeature.CUSTOM) && (
              <TcPasswordField
                name="password"
                gridSize={6}
                passwordLabel="Password*"
                confirmPasswordLabel="Confirm Password*"
                rules={{
                  required: 'Password is required',
                  minLength: {
                    value: 6,
                    message: 'Password must be at least 6 characters long',
                  },
                }}></TcPasswordField>
            )}
            {availableLogins.includes(LoginFeature.CUSTOM) && (
              <Grid item xs={6}>
                <TcTextField
                  name="username"
                  label="Username*"
                  rules={{
                    required: {
                      value: true,
                      message: 'Username is required',
                    },
                    pattern: {
                      value: constants.NAME_REGEX,
                      message: 'Invalid username',
                    },
                  }}></TcTextField>
              </Grid>
            )}
            <Grid item xs={6}>
              <TcTextField
                name="phone"
                label="Contact Phone"
                rules={{
                  pattern: {
                    value: constants.PHONE_REGEX,
                    message: 'Invalid contact number',
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TcTextField
                name="ndnIdentity"
                label="NDN Identity"></TcTextField>
            </Grid>
            <Grid item xs={6}>
              <TcSelectDropdown
                name="roleId"
                label="Role*"
                options={roles}
                optionKey="roleId"
                optionLabel="name"
                rules={{
                  required: {
                    value: true,
                    message: 'Role is required',
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TcSelectWithButtonLastOption
                name="organizationId"
                label="Company/Organization"
                optionKey="organizationId"
                options={organizations}
                optionLabel="name"
                buttonIcon={<GroupAdd />}
                buttonLabel="Add Organization"
                optionButtonClick={handleCreateOrganization}
                optionButtonDisabled={
                  !hasPermission('organizations', 'write')
                }></TcSelectWithButtonLastOption>
            </Grid>
            <Grid item xs={6}>
              <TcTextField name="employeeId" label="Employee ID" />
            </Grid>
            <Grid item xs={6}>
              <TcTextField name="manager" label="Manager Name" />
            </Grid>
            <Grid item xs={6}>
              <TcTextField name="managerId" label="Manager ID" />
            </Grid>
            <Grid item xs={6}>
              <TcTextField name="addressLine" label="Address" />
            </Grid>
            <Grid item xs={6}>
              <TcSelectDropdown
                name="countryId"
                label="Country"
                options={countries}
                optionKey="countryId"
                optionLabel="name"
              />
            </Grid>
            <Grid item xs={6}>
              <TcAutocomplete
                name="stateId"
                label="State"
                optionKey="stateId"
                options={states}
                value={selectedState}
                onValueSelect={onStateSelect}
                getOptionLabel={(state) => state.name}></TcAutocomplete>
            </Grid>
            <Grid item xs={6}>
              <TcTextField name="city" label="City"></TcTextField>
            </Grid>
            <Grid item xs={6}>
              <TcTextField
                name="zipcode"
                label="Zipcode"
                rules={{
                  pattern: {
                    value: constants.ZIP_CODE_REGEX,
                    message: 'Invalid zipcode',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">User status</FormLabel>
                <Controller
                  name="status"
                  control={control}
                  render={({ field }) => (
                    <RadioGroup {...field}>
                      <Grid container direction="row">
                        <Grid item>
                          <FormControlLabel
                            value={UserStatus.ACTIVE}
                            control={
                              <Radio
                                color={
                                  field.value === 'Active'
                                    ? 'success'
                                    : 'default'
                                }
                              />
                            }
                            label="Active"
                          />
                        </Grid>
                        <Grid item>
                          <FormControlLabel
                            value={UserStatus.INACTIVE}
                            control={
                              <Radio
                                color={
                                  field.value === 'Inactive'
                                    ? 'error'
                                    : 'default'
                                }
                              />
                            }
                            label="Inactive"
                          />
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  )}
                />
              </FormControl>
            </Grid>

            <Grid item xs={2}>
              <Button
                size="small"
                type="submit"
                disabled={!isValid}
                onClick={onSaveUser}
                fullWidth
                variant="contained"
                color="info"
                id="save">
                {'Save'}
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                size="small"
                fullWidth
                variant="outlined"
                color="info"
                onClick={onClose}
                id="cancel">
                {'Cancel'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      {openSelectGroupDialog && (
        <SelectGroupDialog
          groups={affectedDynamicGroups}
          onCancel={() => setOpenSelectGroupDialog(false)}
          onSaveGroup={(selectedGroups) => submitUser(selectedGroups)}
          open={openSelectGroupDialog}></SelectGroupDialog>
      )}
    </React.Fragment>
  );
};

export default AddUser;
