import React, { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import {
  Button,
  Grid,
  Breadcrumbs,
  Card,
  CssBaseline,
  Link,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AnyAction } from '@reduxjs/toolkit';

import { customThemeOption, saveThemeSettings } from 'redux/ThemeSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/Store';
import { ICustomThemeOptions } from 'redux/CustomTheme';
import { setLoader, setSnackbarToast } from 'redux/UiStateSlice';
import { constants } from '../../common/constants';
import TcTextField from 'common/components/TcTextField';
import TcSelectWithStringArray from 'common/components/TcSelectWithStringArray';
import { hasPermission } from 'common/helpers/utils';

const useStyles = makeStyles(() => ({
  cardSection: {
    padding: '10px',
    border: '1px solid #0000111E',
    paddingTop: '32px',
  },
  breadcrumbsPadding: {
    padding: '8px',
  },
}));
const fontOptions = [
  'Roboto',
  'Roboto, sans-serif',
  'Arial',
  'Helvetica',
  'Georgia',
  'Times New Roman',
];

const ThemeSettingsPage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { themeData, error } = useSelector(
    (state: RootState) => state.themeState,
  );
  const methods = useForm<ICustomThemeOptions>({
    defaultValues: { ...themeData },
    mode: 'onBlur',
  });

  const {
    handleSubmit,
    reset,
    formState: { isValid },
  } = methods;

  const onSubmit = (data: ICustomThemeOptions) => {
    dispatch(saveThemeSettings({ ...data }) as unknown as AnyAction);
  };

  const onRevertToDefault = () => {
    reset({ ...customThemeOption });
    dispatch(
      saveThemeSettings({ ...customThemeOption }) as unknown as AnyAction,
    );
  };

  useEffect(() => {
    reset({ ...themeData });
    dispatch(
      setLoader({
        loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
        openLoader: false,
      }),
    );
  }, [themeData, reset, dispatch]);

  useEffect(() => {
    error &&
      dispatch(
        setSnackbarToast({
          message: error,
          open: true,
          severity: 'error',
        }),
      );
  }, [error, dispatch]);

  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.breadcrumbsPadding}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="none" color="inherit">
            Settings
          </Link>
          <Typography variant="body1">{'Theme'}</Typography>
        </Breadcrumbs>
      </div>
      <Card elevation={0} className={classes.cardSection}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} md={6}>
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TcTextField
                      label="Primary Color"
                      type="color"
                      name="primaryColor"
                      rules={{
                        required: 'Primary Color is required',
                        pattern: {
                          value: constants.HEX_COLOR_CODE_REGEX,
                          message: 'Invalid hex color code',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TcTextField
                      label="Secondary Color"
                      name="secondaryColor"
                      type="color"
                      rules={{
                        required: 'Secondary Color is required',
                        pattern: {
                          value: constants.HEX_COLOR_CODE_REGEX,
                          message: 'Invalid hex color code',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TcTextField
                      label="Info Color"
                      name="infoColor"
                      type="color"
                      rules={{
                        required: 'Info Color is required',
                        pattern: {
                          value: constants.HEX_COLOR_CODE_REGEX,
                          message: 'Invalid hex color code',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TcTextField
                      label="Success Color"
                      name="successColor"
                      type="color"
                      rules={{
                        required: 'Success Color is required',
                        pattern: {
                          value: constants.HEX_COLOR_CODE_REGEX,
                          message: 'Invalid hex color code',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TcTextField
                      label="Error Color"
                      name="errorColor"
                      type="color"
                      rules={{
                        required: 'Error Color is required',
                        pattern: {
                          value: constants.HEX_COLOR_CODE_REGEX,
                          message: 'Invalid hex color code',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TcTextField
                      label="Warning Color"
                      name="warningColor"
                      type="color"
                      rules={{
                        required: 'Warning Color is required',
                        pattern: {
                          value: constants.HEX_COLOR_CODE_REGEX,
                          message: 'Invalid hex color code',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TcSelectWithStringArray
                      name="font"
                      label="Select font"
                      options={fontOptions}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2} justifyContent={'center'}>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="info"
                          type="submit"
                          disabled={
                            !isValid ||
                            !hasPermission('settings.theme', 'write')
                          }>
                          Save
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          fullWidth
                          variant="outlined"
                          color="info"
                          onClick={onRevertToDefault}>
                          {'Revert to defaults'}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </Grid>
        </Grid>
      </Card>
    </React.Fragment>
  );
};

export default ThemeSettingsPage;
