import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
} from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import FilterIconButton from 'common/components/FilterIconButton';
import CustomExportButton from './CustomExportButton';

type CustomToolbarProps = {
  handleFilterClick: () => void;
  handleExportClick: (format: string) => void;
  handleSearchChange?: (searchText: string) => void;
  searchText?: string;
};

function CustomToolbar({
  handleFilterClick,
  handleExportClick,
  handleSearchChange,
  searchText = '',
}: CustomToolbarProps) {
  const handleClear = () => {
    handleSearchChange?.('');
  };

  const onSearchChange = (value: string) => {
    handleSearchChange?.(value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearchChange?.(searchText);
    }
  };
  return (
    <GridToolbarContainer
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
      <div>
        <TextField
          key="Search"
          autoFocus
          aria-label="Search"
          variant="standard"
          placeholder="Username or email"
          type="text"
          value={searchText}
          onKeyUp={handleKeyPress}
          onChange={(e) => onSearchChange(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => handleSearchChange?.(searchText)}
                  aria-label="Clear"
                  size="small">
                  <SearchIcon />
                </IconButton>
                <IconButton
                  onClick={handleClear}
                  aria-label="Clear"
                  size="small">
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <GridToolbarColumnsButton slotProps={{ button: { color: 'info' } }} />
        <FilterIconButton handleFilterClick={handleFilterClick} />
        <CustomExportButton
          handleExportClick={handleExportClick}></CustomExportButton>
      </div>
    </GridToolbarContainer>
  );
}

export default CustomToolbar;
