import React, { useEffect, useState, useCallback } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from '@mui/material';
import dayjs from 'dayjs';
import { getTimezone } from 'common/helpers/utils';

interface CountdownDialogProps {
  open: boolean;
  countDownTime: number; // This should be the number of seconds for the countdown
  onClose: () => void;
  onTimeUp: () => void;
  handleDisconnectOkayClick: () => void;
  deviceTimezone?: string;
  isShowOnlyCountdown?: boolean;
}

const CountdownDialog: React.FC<CountdownDialogProps> = ({
  open,
  countDownTime,
  onClose,
  onTimeUp,
  deviceTimezone,
  handleDisconnectOkayClick,
  isShowOnlyCountdown = false,
}) => {
  const [timeLeft, setTimeLeft] = useState<number>(0);
  const [isTimeUp, setIsTimeUp] = useState<boolean>(false);
  const [deviceLocalTime, setDeviceLocalTime] = useState('');
  useEffect(() => {
    const updateTimeLeft = () => {
      const remainingTime = Math.floor(countDownTime - dayjs().unix());
      setTimeLeft(remainingTime > 0 ? remainingTime : 0);
      setDeviceLocalTime(
        dayjs()
          .tz(deviceTimezone ?? getTimezone())
          .format('hh.mmA z'),
      );
      if (remainingTime <= 0) {
        setIsTimeUp(true);
        onTimeUp();
      }
    };
    updateTimeLeft(); // Initialize the remaining time
    const intervalId = setInterval(updateTimeLeft, 1000);

    return () => clearInterval(intervalId); // Clear the interval on unmount
  }, [countDownTime, deviceTimezone, onTimeUp]);

  const handleOkayClick = useCallback(() => {
    if (isTimeUp) {
      handleDisconnectOkayClick();
    } else {
      onClose();
    }
  }, [isTimeUp, onClose, handleDisconnectOkayClick]);

  const formatTime = (seconds: number): string => {
    const days = Math.floor(seconds / 86400); // 86400 seconds in a day
    const hours = Math.floor((seconds % 86400) / 3600); // remaining hours after days
    const minutes = Math.floor((seconds % 3600) / 60); // remaining minutes after hours
    const remainingSeconds = seconds % 60; // remaining seconds after minutes

    const pad = (num: number) => (num < 10 ? '0' : '') + num;
    if (days > 0) {
      return `${days} day${days > 1 ? 's' : ''} ${pad(hours)}:${pad(
        minutes,
      )}:${pad(remainingSeconds)} hours`;
    }
    if (hours > 0) {
      return `${hours}:${pad(minutes)}:${pad(remainingSeconds)} hours`;
    }
    return `${minutes}:${pad(remainingSeconds)} minutes`;
  };

  const countdownText = `${formatTime(timeLeft)}`;

  return (
    <>
      {!isShowOnlyCountdown && (
        <Dialog open={open} disableEscapeKeyDown maxWidth="xs">
          <DialogContent>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column">
              <Typography variant="h6">
                {isTimeUp
                  ? 'Your access time window has closed.'
                  : `Please note that your access is scheduled to close in ${countdownText}`}
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Box display="flex" justifyContent="center" width="100%">
              <Button
                onClick={handleOkayClick}
                color="info"
                variant="contained">
                OK
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      )}
      {isShowOnlyCountdown && !isTimeUp && (
        <Typography component="span" sx={{ fontSize: 18 }}>
          {`Local Time at the Device is: `}
          <Typography component="span" sx={{ fontWeight: 'bold' }}>
            {deviceLocalTime}
          </Typography>
          <Typography component="span" sx={{ paddingLeft: 4 }}>
            Access will close in <strong>{`${countdownText}`}</strong>
          </Typography>
        </Typography>
      )}
    </>
  );
};

export default CountdownDialog;
