import * as React from 'react';
import { useEffect } from 'react';
import { Avatar, Box, Link, Theme } from '@mui/material';
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import green from '@mui/material/colors/green';
import grey from '@mui/material/colors/grey';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { hasPermission, stringAvatar } from 'common/helpers/utils';
import { UserStatus } from 'common/enums';
import { User, UserGroup } from '../types';
import NoRowsOverlay from 'common/components/NoRowsOverlay';
import { useNavigate } from 'react-router-dom';

type UsersTableProps = {
  data: User[];
};

const GroupUsersSubTable: React.FC<UsersTableProps> = (props) => {
  const [rows, setRows] = React.useState<User[]>(props.data);
  const navigate = useNavigate();
  useEffect(() => {
    setRows(props.data);
  }, [props.data]);

  const handleEmailClick = React.useCallback(
    (user: User) => {
      navigate(`/portal/users/summary`, {
        state: {
          user: user,
        },
      });
    },
    [navigate],
  );
  const columns = React.useMemo<GridColDef[]>(
    () => [
      {
        field: 'avatar',
        headerName: '',
        width: 100,
        renderCell: (params) => (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100%">
            <Avatar
              {...stringAvatar(
                `${params.row.firstName ?? ''} ${params.row.lastName ?? ''}`,
              )}
            />
          </Box>
        ),
      },
      {
        field: 'firstName',
        type: 'string',
        headerName: 'Display Name',
        flex: 1,
        renderCell: (params) => (
          <Link
            component="button"
            sx={{
              color: (theme: Theme) => theme.palette.info.main,
              textDecorationColor: (theme: Theme) => theme.palette.info.main,
            }}
            onClick={() => handleEmailClick(params.row)}>
            {params.row.firstName} {params.row.lastName}
          </Link>
        ),
      },
      { field: 'roleName', type: 'string', headerName: 'Trust Role', flex: 1 },
      {
        field: 'email',
        type: 'string',
        headerName: 'Email',
        flex: 1,
      },
      {
        field: 'groups',
        type: 'string',
        headerName: 'Group(s)',
        flex: 1,
        valueGetter: (value) => {
          const groups = (value || []) as UserGroup[];
          return groups.map((obj) => obj.name).join(', ');
        },
      },
      { field: 'country', type: 'string', headerName: 'Country', flex: 1 },
      { field: 'state', type: 'string', headerName: 'State', flex: 1 },
      { field: 'city', type: 'string', headerName: 'City', flex: 1 },
      {
        field: 'organization',
        type: 'string',
        headerName: 'Company/Organization',
        flex: 1,
        valueGetter: (value: any) => {
          return value?.name ?? '';
        },
      },
      {
        field: 'status',
        type: 'string',
        headerName: 'Status',
        width: 60,
        renderCell: (params) => {
          const status = params.row.status || '';
          if (status === UserStatus.ACTIVE) {
            return <CheckCircleOutlineIcon sx={{ color: green[500] }} />;
          } else if (status === UserStatus.INACTIVE) {
            return <BlockIcon sx={{ color: grey[500] }} />;
          } else {
            return status;
          }
        },
      },
    ],
    [handleEmailClick],
  );

  return (
    <>
      <DataGrid
        disableVirtualization
        columns={columns}
        rows={rows}
        getRowId={(row) => row.userId}
        hideFooter={true}
        hideFooterPagination={true}
        autoHeight
        slots={{
          noRowsOverlay: () => (
            <NoRowsOverlay
              hasAccess={hasPermission('users.summary', 'read')}
              name="Users"
            />
          ),
        }}
        sx={{
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: '600',
          },
        }}
      />
    </>
  );
};
export default GroupUsersSubTable;
