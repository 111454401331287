import { useState } from 'react';
import {
  Grid,
  Button,
  Card,
  CardContent,
  Typography,
  CardHeader,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { User } from 'pages/users/types';
import EditProfileForm from './EditProfileForm';
import { useContentStyles } from 'common/styles/useContentStyles';

type ProfileTabProps = {
  user: User;
  getUpdatedUser: () => void;
};

const ProfileTab: React.FC<ProfileTabProps> = ({ user, getUpdatedUser }) => {
  const classes = useContentStyles();
  const [isEditing, setIsEditing] = useState(false);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    getUpdatedUser();
    setIsEditing(false);
  };

  const onCancelEdit = () => {
    setIsEditing(false);
  };

  return (
    <Grid container spacing={2} justifyContent={'center'}>
      <Grid item xs={12}>
        <Card variant="outlined">
          <CardHeader
            title="Personal Details"
            action={
              !isEditing && (
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      onClick={handleEditClick}
                      color="info"
                      startIcon={<EditIcon />}>
                      {'Edit'}
                    </Button>
                  </Grid>
                </Grid>
              )
            }
            disableTypography
            className={classes.cardHeader}></CardHeader>
          <CardContent>
            {isEditing ? (
              <EditProfileForm
                user={user}
                onCancelEdit={onCancelEdit}
                onUpdateUser={handleSaveClick}></EditProfileForm>
            ) : (
              <Grid container spacing={2} justifyContent={'center'}>
                <Grid item xs={4}>
                  <Typography>First Name</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body2">{user.firstName}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography>Last Name</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body2">{user.lastName}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography>Middle Name</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body2">{user.middleName}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography>Role</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body2">{user.roleName}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography>Email</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body2">{user.email}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography>Phone</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body2">{user.phone}</Typography>
                </Grid>
              </Grid>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ProfileTab;
