import React, { useCallback, useEffect, useRef, useState } from 'react';

import EditIcon from '@mui/icons-material/EditLocation';
import {
  CssBaseline,
  Card,
  Drawer,
  Typography,
  Grid,
  Button,
  CardContent,
  CardHeader,
  Breadcrumbs,
  IconButton,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import {
  Device,
  DeviceAccessMethodWithRule,
  DeviceDetails,
  DeviceFormMasterData,
} from '../types';
import SiteMap from 'common/components/SiteMap';
import EditDevice from '../devicesList/EditDevice';
import apiClient from 'common/apiClientAxios';
import { setLoader, setSnackbarToast } from 'redux/UiStateSlice';
import { getUser, hasPermission, isEndUser } from 'common/helpers/utils';
import { DrawerHeader } from 'common/styles/styledComponents';
import {
  DeviceAccessMethod,
  FeatureStatus,
  Protocol,
  RemoteAccessType,
} from 'common/enums';
import VideoModal from 'common/components/VideoModal';
import assets from '../../../assets';
import { constants } from 'common/constants';
import { FeatureSetting } from 'pages/settings/types';
import RemoteAccessSSH from '../remoteAccess/RemoteAccessSSH';
import RemoteAccessRDP from '../remoteAccess/RemoteAccessRDP';
import { deviceDefaultValues } from '../devicesList/Devices';
import AddManufacturerDialog from '../devicesList/AddManufacturerDialog';
import RemoteAccessHTTP from '../remoteAccess/RemoteAccessHTTP';
import timezones from 'assets/staticdata/timezones.json';

export const useStyles = makeStyles(() => ({
  formPaper: {
    width: '40%',
  },
  contentSection: {
    padding: '4px',
    marginTop: '10px',
  },
  contentPadding: {
    padding: '2px',
  },
  value: {
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
  },
  cardHeader: {
    fontSize: '20px',
    fontWeight: 'normal',
  },
}));

const DeviceSummaryTab: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { device, deviceFormMasterData: deviceFormMasterDataLocationState } =
    (location.state as {
      device: Device;
      deviceFormMasterData: DeviceFormMasterData;
    }) || {};
  const [isEdit, setEdit] = useState(false);
  const [deviceDetails, setDeviceDetails] = useState<DeviceDetails>(
    device || { ...deviceDefaultValues },
  );
  const [isRemoteAccess, setRemoteAccess] = useState(false);
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');

  const [openSSHAccessModal, setOpenSSHAccessModal] = useState(false);
  const [openRDPAccessModal, setOpenRDPAccessModal] = useState(false);
  const [openHTTPSAccessModal, setOpenHTTPSAccessModal] = useState(false);
  const onCloseEdit = () => {
    setEdit(false);
  };
  const onSaveDevice = () => {
    setEdit(false);
    getDeviceDetails();
  };

  const openEditForm = async () => {
    if (
      deviceDetails.connectionProfiles &&
      deviceDetails.connectionProfiles.length > 0
    ) {
      //fetch connection profiles with decrypted password
      try {
        dispatch(
          setLoader({
            loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
            openLoader: true,
          }),
        );
        const detailsResponse = await apiClient.post(
          `devices/connectionProfiles`,
          deviceDetails.connectionProfiles,
        );
        setDeviceDetails((prevState) => ({
          ...prevState,
          connectionProfiles: detailsResponse.data.data,
        }));
        dispatch(
          setLoader({
            loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
            openLoader: false,
          }),
        );
      } catch (error: any) {
        dispatch(
          setLoader({
            loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
            openLoader: false,
          }),
        );
        const errorData =
          error.response?.data?.meta?.message || String(error.message);
        dispatch(
          setSnackbarToast({
            message: errorData,
            open: true,
            severity: 'error',
          }),
        );
      }
    }
    setEdit(true);
  };

  const isRunEffect = useRef(true);
  const getDeviceDetails = useCallback(async () => {
    try {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        }),
      );
      const detailsResponse = await apiClient.get(`devices/${device.deviceId}`);
      const details = detailsResponse.data.data as DeviceDetails;
      setDeviceDetails((prevState) => ({
        ...prevState,
        ...details,
      }));
      //If feature is code_devices.remote_access, then show access modal else show video modal
      const featuresResponse = await apiClient.get('/settings/features');
      const featureList = featuresResponse.data.data as FeatureSetting[];
      const remoteAccessSettings = featureList.find(
        (feature) => feature.featureId === 'code_devices.remote_access',
      );
      setRemoteAccess(remoteAccessSettings?.status === FeatureStatus.ENABLED);
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        }),
      );
    } catch (error: any) {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        }),
      );
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: 'error',
        }),
      );
    }
  }, [device.deviceId, dispatch]);

  const updateLastAccess = useCallback(async () => {
    await apiClient.put(`devices/${device.deviceId}/updateLastAccess`);
  }, [device.deviceId]);

  useEffect(() => {
    if (!device?.deviceId) {
      dispatch(
        setSnackbarToast({
          message: 'Please select device from list',
          open: true,
          severity: 'error',
        }),
      );
      navigate('/portal/devices/summary');
    }
    if (isRunEffect.current) {
      getDeviceDetails();
      updateLastAccess();
      isRunEffect.current = false;
    }
  }, [
    device,
    dispatch,
    getDeviceDetails,
    isRunEffect,
    navigate,
    updateLastAccess,
  ]);

  const handleCloseVideoModal = () => {
    setOpenVideoModal(false);
  };

  const handleCloseSSHAccessModal = () => {
    setOpenSSHAccessModal(false);
  };

  const handleCloseRDPAccessModal = () => {
    setOpenRDPAccessModal(false);
  };
  const handleCloseHTTPSAccessModal = () => {
    setOpenHTTPSAccessModal(false);
  };

  const handleRemoteAccessType = (
    accessProfile: DeviceAccessMethodWithRule,
  ) => {
    const loggedInUser = getUser();
    const remoteAccessType =
      (loggedInUser?.deviceAccessPreference as RemoteAccessType) ??
      RemoteAccessType.DIALOG;
    if (remoteAccessType !== RemoteAccessType.DIALOG) {
      sessionStorage.getItem('user')
        ? sessionStorage.setItem(
            'remote_access',
            JSON.stringify({ ...device, accessProfile }),
          )
        : localStorage.setItem(
            'remote_access',
            JSON.stringify({ ...device, accessProfile }),
          );
      openNewTab(accessProfile.protocol as DeviceAccessMethod);
    } else {
      setDeviceDetails((prevDevice) => {
        return { ...prevDevice, accessProfile };
      });
      handleDialogAccess(accessProfile.protocol);
    }
  };

  const openNewTab = (accessMethod: DeviceAccessMethod) => {
    const method = accessMethod.includes(Protocol.HTTP)
      ? Protocol.HTTPS
      : accessMethod;
    const url = `/devices/remoteAccess/${deviceDetails.deviceId}/${method}`;
    const newTab = window.open(url, '_blank');
    if (newTab) {
      newTab.focus();
    } else {
      console.error(
        'Unable to open a new tab. Make sure pop-ups are allowed in the browser.',
      );
    }
  };

  const handleDialogAccess = (accessMethod: string) => {
    switch (accessMethod?.toLowerCase()) {
      case DeviceAccessMethod.SSH:
        setOpenSSHAccessModal(true);
        break;
      case DeviceAccessMethod.RDP:
        setOpenRDPAccessModal(true);
        break;
      case DeviceAccessMethod.HTTP:
      case DeviceAccessMethod.HTTPS:
      case DeviceAccessMethod.HTTP_S:
        setOpenHTTPSAccessModal(true);
        break;
    }
  };

  const handleLocalAccess = (accessMethod: DeviceAccessMethod) => {
    setVideoUrl(
      accessMethod.toLowerCase() === DeviceAccessMethod.SSH
        ? assets.videos.SSHVideo
        : assets.videos.RDPSCADA,
    );
    setOpenVideoModal(true);
  };

  const handleAccessClick = (profile: DeviceAccessMethodWithRule) => {
    if (isRemoteAccess) {
      if (!deviceDetails.siteId) {
        dispatch(
          setSnackbarToast({
            message: constants.DEVICE_SITE_MISSING,
            open: true,
            severity: 'error',
          }),
        );
      } else handleRemoteAccessType(profile);
    } else {
      handleLocalAccess(profile.protocol as DeviceAccessMethod);
    }
  };

  const [deviceFormMasterData, setDeviceFormMasterData] =
    useState<DeviceFormMasterData>(
      deviceFormMasterDataLocationState ?? {
        accessMethods: [],
        deviceTypes: [],
        groups: [],
        manufacturers: [],
        sites: [],
      },
    );
  const [openAddManufacturerDialogue, setOpenAddManufacturerDialogue] =
    React.useState(false);
  const getManufacturers = async () => {
    try {
      const manufacturersResponse = await apiClient.get('/manufacturers');
      setDeviceFormMasterData((prevState) => ({
        ...prevState,
        manufacturers: manufacturersResponse.data.data,
      }));
    } catch (error: any) {
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: 'error',
        }),
      );
    }
    setOpenAddManufacturerDialogue(false);
  };

  const handleFavoriteDevice = async () => {
    try {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        }),
      );
      if (deviceDetails.isFavorite) {
        await apiClient.delete(
          `users/favoriteDevice/${deviceDetails.deviceId}`,
        );
      } else {
        await apiClient.put(`users/favoriteDevice/${deviceDetails.deviceId}`);
      }
      const detailsResponse = await apiClient.get(
        `devices/${deviceDetails.deviceId}`,
      );
      const details = detailsResponse.data.data as DeviceDetails;
      setDeviceDetails(details);
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        }),
      );
    } catch (error: any) {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        }),
      );
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: 'error',
        }),
      );
    }
  };
  const theme = useTheme();
  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.contentPadding}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            to="/portal/devices/summary"
            style={{
              color: theme.palette.text.secondary,
            }}>
            Devices
          </Link>
          {deviceDetails.siteName && (
            <Typography variant="body1">{deviceDetails.siteName}</Typography>
          )}
          <Typography variant="body1">{deviceDetails.name}</Typography>
        </Breadcrumbs>
      </div>
      <Card elevation={0}>
        <CardHeader
          disableTypography
          title={deviceDetails.name}
          className={classes.cardHeader}
          action={
            <Grid container spacing={2}>
              <Grid item>
                <IconButton onClick={handleFavoriteDevice}>
                  {deviceDetails.isFavorite ? (
                    <StarIcon color="secondary" />
                  ) : (
                    <StarOutlineIcon />
                  )}
                </IconButton>
              </Grid>
              {!isEndUser() && hasPermission('devices.summary', 'write') && (
                <Grid item>
                  <Button
                    onClick={openEditForm}
                    color="info"
                    startIcon={<EditIcon />}>
                    {'Edit Device'}
                  </Button>
                </Grid>
              )}
            </Grid>
          }></CardHeader>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card variant="outlined">
                  <CardHeader
                    title="Layout"
                    className={classes.cardHeader}
                    disableTypography
                  />
                  {deviceDetails.site?.latitude &&
                  deviceDetails.site?.longitude ? (
                    <SiteMap
                      latitude={parseFloat(deviceDetails.site?.latitude)}
                      longitude={parseFloat(
                        deviceDetails.site?.longitude,
                      )}></SiteMap>
                  ) : (
                    <div>
                      <p>Device location is not provided</p>
                    </div>
                  )}
                </Card>
              </Grid>
              <Grid item xs={12} container spacing={1}>
                {deviceDetails.accessMethods?.map(
                  (profile: DeviceAccessMethodWithRule, index: number) => (
                    <Grid item xs={3} key={`${index}-${profile.name}`}>
                      <Button
                        size="small"
                        fullWidth
                        variant="contained"
                        color="info"
                        onClick={() => handleAccessClick(profile)}>
                        {profile.name}
                      </Button>
                    </Grid>
                  ),
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card variant="outlined">
                  <CardHeader
                    title="Device Information"
                    className={classes.cardHeader}
                    disableTypography
                    sx={{ paddingBottom: '0px' }}
                  />
                  <CardContent>
                    <Grid container>
                      <Grid item xs={5}>
                        <Typography>Alias(es)</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography variant="body2">
                          {deviceDetails.alias ? deviceDetails.alias : '-'}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography>Site</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography variant="body2">
                          {deviceDetails.siteName}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography>Type</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography variant="body2">
                          {deviceDetails.type ?? '-'}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography>Access Methods</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography variant="body2">
                          {deviceDetails.accessMethods?.map(
                            (profile, index) => {
                              return `${profile.protocol} ${
                                index <
                                (deviceDetails.accessMethods?.length ?? 0) - 1
                                  ? ', '
                                  : ''
                              }`;
                            },
                          ) ?? '-'}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography>Manufacturer</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography variant="body2">
                          {deviceDetails.manufacturerName
                            ? deviceDetails.manufacturerName
                            : '-'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Card variant="outlined">
              <CardHeader
                title="Site Information"
                className={classes.cardHeader}
                disableTypography
                sx={{ paddingBottom: '0px' }}
              />
              <CardContent>
                <Grid container>
                  <Grid item xs={5}>
                    <Typography>Name</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant="body2">
                      {deviceDetails.site?.name ?? '-'}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography>Alias(es)</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant="body2">
                      {deviceDetails.site?.alias
                        ? deviceDetails.site?.alias
                        : '-'}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography>Owner</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant="body2">
                      {deviceDetails.site?.ownerName
                        ? deviceDetails.site?.ownerName
                        : '-'}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography>Operator</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant="body2">
                      {deviceDetails.site?.operatorName
                        ? deviceDetails.site?.operatorName
                        : '-'}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography>City</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant="body2">
                      {deviceDetails.site?.city
                        ? deviceDetails.site?.city
                        : '-'}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography>State</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant="body2">
                      {deviceDetails.site?.state ?? '-'}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography>Country</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant="body2">
                      {deviceDetails.site?.country ?? '-'}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography>Timezone</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant="body2">
                      {deviceDetails.site?.timezone
                        ? timezones.find(
                            (timezone) =>
                              timezone.value === deviceDetails.site?.timezone,
                          )?.label
                        : '-'}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Drawer
          anchor="right"
          open={isEdit}
          classes={{ paper: classes.formPaper }}>
          <DrawerHeader />
          <EditDevice
            onSaveUpdateTable={onSaveDevice}
            onClose={onCloseEdit}
            selectedDevice={deviceDetails}
            deviceFormMasterData={deviceFormMasterData}
            openAddManufacturerForm={() =>
              setOpenAddManufacturerDialogue(true)
            }></EditDevice>
        </Drawer>
      </Card>
      {openSSHAccessModal && (
        <RemoteAccessSSH
          open={openSSHAccessModal}
          onClose={handleCloseSSHAccessModal}
          device={deviceDetails}
        />
      )}
      {openRDPAccessModal && (
        <RemoteAccessRDP
          open={openRDPAccessModal}
          onClose={handleCloseRDPAccessModal}
          device={deviceDetails}
        />
      )}
      {openHTTPSAccessModal && (
        <RemoteAccessHTTP
          open={openHTTPSAccessModal}
          onClose={handleCloseHTTPSAccessModal}
          device={deviceDetails}
        />
      )}

      <VideoModal
        open={openVideoModal}
        onClose={handleCloseVideoModal}
        videoUrl={videoUrl}
      />
      {openAddManufacturerDialogue && (
        <AddManufacturerDialog
          open={openAddManufacturerDialogue}
          onCancel={() => setOpenAddManufacturerDialogue(false)}
          onSave={getManufacturers}></AddManufacturerDialog>
      )}
    </React.Fragment>
  );
};

export default DeviceSummaryTab;
