import React, { useCallback, useRef, useState } from 'react';

import EditSiteIcon from '@mui/icons-material/EditLocation';
import {
  CssBaseline,
  Card,
  Drawer,
  Typography,
  Grid,
  Button,
  CardContent,
  CardHeader,
  IconButton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';

import { Site, SiteFormMasterData } from '../types';
import SiteMap from 'common/components/SiteMap';
import EditSite from '../sitesList/EditSite';
import Devices from 'pages/devices/devicesList/Devices';
import { constants } from 'common/constants';
import { DrawerHeader } from 'common/styles/styledComponents';
import apiClient from 'common/apiClientAxios';
import { setLoader, setSnackbarToast } from 'redux/UiStateSlice';
import AddOperatorDialog from '../sitesList/AddOperatorDialog';
import AddOwnerDialog from '../sitesList/AddOwnerDialog';
import { isEndUser } from '../../../common/helpers/utils';
import { hasPermission } from 'common/helpers/utils';
import timezones from 'assets/staticdata/timezones.json';

export const useStyles = makeStyles(() => ({
  formPaper: {
    width: '40%',
  },
  contentSection: {
    padding: '4px',
    border: '1px solid #0000111E',
    marginTop: '10px',
  },
  contentPadding: {
    padding: '2px',
  },
  value: {
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
  },
  cardHeader: {
    fontSize: '20px',
    fontWeight: 'normal',
    padding: '16px 16px 0px 16px',
  },
  siteCardHeader: {
    fontSize: '20px',
    fontWeight: 'normal',
  },
}));
type SiteSummaryTabProps = {
  site: Site;
  onGetUpdatedSite: () => void;
  handleFavoriteSite: (site: Site) => void;
};

const SiteSummaryTab: React.FC<SiteSummaryTabProps> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isEdit, setEdit] = useState(false);
  const onCloseEdit = () => {
    setEdit(false);
  };
  const onSaveSite = () => {
    setEdit(false);
    props.onGetUpdatedSite();
  };

  const openEditForm = () => {
    setEdit(true);
  };
  const isRunEffect = useRef(true);
  const [siteFormMasterData, setSiteFormMasterData] =
    useState<SiteFormMasterData>({
      siteTypes: [],
      siteOwners: [],
      siteOperators: [],
      groups: [],
      countries: [],
      complianceLevels: [],
      states: [],
    });
  const getMasterDataForSite = useCallback(async () => {
    try {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        }),
      );
      const formMasterResponse = await apiClient.get(`/sites/form-data`);
      const formMasters = formMasterResponse.data.data as SiteFormMasterData;
      setSiteFormMasterData(formMasters);
      await apiClient.put(`sites/${props.site.siteId}/updateLastAccess`);
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        }),
      );
    } catch (error: any) {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        }),
      );
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: 'error',
        }),
      );
    }
  }, [dispatch, props.site.siteId]);

  React.useEffect(() => {
    if (isRunEffect.current) {
      getMasterDataForSite();
      isRunEffect.current = false;
    }
  }, [getMasterDataForSite, isRunEffect]);

  const [openAddOperatorDialog, setOpenAddOperatorDialog] =
    React.useState(false);
  const getOperators = async () => {
    try {
      const siteOperatorsResponse = await apiClient.get('/site-operators');
      setSiteFormMasterData((prevState) => ({
        ...prevState,
        siteOperators: siteOperatorsResponse.data.data,
      }));
    } catch (error: any) {
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: 'error',
        }),
      );
    }
    setOpenAddOperatorDialog(false);
  };

  const [openAddOwnerDialog, setOpenAddOwnerDialog] = React.useState(false);
  const getOwners = async () => {
    try {
      const siteOwnersResponse = await apiClient.get('/site-owners');
      setSiteFormMasterData((prevState) => ({
        ...prevState,
        siteOwners: siteOwnersResponse.data.data,
      }));
    } catch (error: any) {
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: 'error',
        }),
      );
    }
    setOpenAddOwnerDialog(false);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Card elevation={0} className={classes.contentSection}>
        <CardHeader
          disableTypography
          title={props.site.name}
          className={classes.siteCardHeader}
          action={
            <Grid container spacing={2}>
              <Grid item>
                <IconButton
                  onClick={() => props.handleFavoriteSite(props.site)}>
                  {props.site.isFavorite ? (
                    <StarIcon color="secondary" />
                  ) : (
                    <StarOutlineIcon />
                  )}
                </IconButton>
              </Grid>
              {!isEndUser() && hasPermission('sites.summary', 'write') && (
                <Grid item>
                  <Button
                    onClick={openEditForm}
                    color="info"
                    startIcon={<EditSiteIcon />}>
                    {'Edit Site'}
                  </Button>
                </Grid>
              )}
            </Grid>
          }></CardHeader>

        <Grid container spacing={2}>
          {/* Hide sitemap, POC and Site information if user is role- EndUser */}
          {!isEndUser() && (
            <>
              <Grid item xs={6}>
                {props.site.latitude && props.site.longitude ? (
                  <Card variant="outlined">
                    <SiteMap
                      latitude={parseFloat(props.site.latitude)}
                      longitude={parseFloat(props.site.longitude)}></SiteMap>
                  </Card>
                ) : (
                  <>Site location is not provided</>
                )}
              </Grid>
              <Grid item xs={3}>
                <Card variant="outlined">
                  <CardHeader
                    className={classes.cardHeader}
                    title="Site Information"
                    disableTypography
                    sx={{ paddingBottom: '0px' }}></CardHeader>
                  <CardContent>
                    <Grid container>
                      <Grid item xs={5}>
                        {' '}
                        <Typography>Alias(es)</Typography>
                      </Grid>
                      <Grid item xs={7} className={classes.value}>
                        <Typography variant="body2">
                          {props.site.alias ? props.site.alias : '-'}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography>Owner</Typography>
                      </Grid>
                      <Grid item xs={7} className={classes.value}>
                        <Typography variant="body2">
                          {props.site.ownerName ? props.site.ownerName : '-'}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography>Operator</Typography>
                      </Grid>
                      <Grid item xs={7} className={classes.value}>
                        <Typography variant="body2">
                          {props.site.operatorName
                            ? props.site.operatorName
                            : '-'}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography>City</Typography>
                      </Grid>
                      <Grid item xs={7} className={classes.value}>
                        <Typography variant="body2">
                          {props.site.city ? props.site.city : '-'}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography>State</Typography>
                      </Grid>
                      <Grid item xs={7} className={classes.value}>
                        <Typography variant="body2">
                          {props.site.state ? props.site.state : '-'}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography>Country</Typography>
                      </Grid>
                      <Grid item xs={7} className={classes.value}>
                        <Typography variant="body2">
                          {props.site.country ? props.site.country : '-'}
                        </Typography>
                      </Grid>
                      <Grid item xs={5}>
                        <Typography>Timezone</Typography>
                      </Grid>
                      <Grid item xs={7}>
                        <Typography variant="body2">
                          {props.site.timezone
                            ? timezones.find(
                                (timezone) =>
                                  timezone.value === props.site.timezone,
                              )?.label
                            : '-'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={3}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Card variant="outlined">
                      <CardHeader
                        className={classes.cardHeader}
                        title="Primary POC"
                        disableTypography
                        sx={{ paddingBottom: '0px' }}></CardHeader>
                      <CardContent>
                        <Grid container>
                          <Grid item xs={4}>
                            <Typography>Name</Typography>
                          </Grid>
                          <Grid item xs={8} className={classes.value}>
                            <Typography variant="body2">
                              {props.site.contactName
                                ? props.site.contactName
                                : '-'}
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography>Email</Typography>
                          </Grid>
                          <Grid item xs={8} className={classes.value}>
                            <Typography variant="body2">
                              {props.site.contactEmail
                                ? props.site.contactEmail
                                : '-'}
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography>Phone</Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography variant="body2">
                              {props.site.contactPhone
                                ? props.site.contactPhone
                                : '-'}
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography>Company</Typography>
                          </Grid>
                          <Grid item xs={8} className={classes.value}>
                            <Typography variant="body2">
                              {props.site.contactCompany
                                ? props.site.contactCompany
                                : '-'}{' '}
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Devices
              isHideBreadCrumb={true}
              siteId={props.site.siteId}></Devices>
          </Grid>
        </Grid>
        <Drawer
          anchor="right"
          open={isEdit}
          classes={{ paper: classes.formPaper }}>
          <DrawerHeader />
          <EditSite
            onSaveUpdateTable={onSaveSite}
            onClose={onCloseEdit}
            siteFormMasterData={siteFormMasterData}
            selectedSite={props.site}
            openOperatorForm={() => setOpenAddOperatorDialog(true)}
            openOwnerForm={() => setOpenAddOwnerDialog(true)}></EditSite>
        </Drawer>
        {openAddOperatorDialog && (
          <AddOperatorDialog
            open={openAddOperatorDialog}
            onCancel={() => setOpenAddOperatorDialog(false)}
            onSave={getOperators}></AddOperatorDialog>
        )}
        {openAddOwnerDialog && (
          <AddOwnerDialog
            open={openAddOwnerDialog}
            onCancel={() => setOpenAddOwnerDialog(false)}
            onSave={getOwners}></AddOwnerDialog>
        )}
      </Card>
    </React.Fragment>
  );
};

export default SiteSummaryTab;
