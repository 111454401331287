import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';

type Site = {
  siteId: string;
  name: string;
};

type Device = {
  deviceId: string;
  name: string;
  siteId: string;
  connectionProfiles: { protocol: string }[];
};

interface SunburstData {
  name: string;
  children?: SunburstData[];
  value?: number;
}

interface ZoomableSunburstProps {
  sites: Site[];
  devices: Device[];
  themeMode: 'light' | 'dark';
  width?: number;
}

const ZoomableSunburst: React.FC<ZoomableSunburstProps> = ({
  sites,
  devices,
  width = 928,
  themeMode,
}) => {
  const chartRef = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    const height = width;
    const radius = width / 6;
    const textColor = themeMode === 'light' ? '#000000' : '#FFFFFF';
    const data = {
      name: 'sites',
      children: sites.map((site) => {
        const siteDevices = devices.filter(
          (device) => device.siteId === site.siteId,
        );
        const protocolCounts = new Map<string, number>();
        const protocolDevices = new Map<string, string[]>();
        siteDevices.forEach((device) => {
          device.connectionProfiles?.forEach((profile) => {
            const protocolName = profile.protocol;
            protocolCounts.set(
              protocolName,
              (protocolCounts.get(protocolName) || 0) + 1,
            );
            protocolDevices.set(protocolName, [
              ...(protocolDevices.get(protocolName) || []),
              device.name,
            ]);
          });
        });

        // Convert the map to an array of children
        const protocolChildren = Array.from(protocolCounts.entries()).map(
          ([protocol, count]) => ({
            name: protocol,
            devices: count,
            children:
              protocolDevices
                .get(protocol)
                ?.map((deviceId) => ({ name: deviceId, value: 1 })) || [],
          }),
        );
        return {
          name: site.name,
          devices: siteDevices.length,
          children: protocolChildren,
        };
      }),
    };
    // Create color scale
    const color = d3.scaleOrdinal(d3.schemeCategory10);
    // Compute the layout
    const hierarchy: any = d3
      .hierarchy(data)
      .sum((d: any) => d.value || 0)
      .sort((a, b) => (b.value || 0) - (a.value || 0));

    const root = d3
      .partition<SunburstData>()
      .size([2 * Math.PI, hierarchy.height + 1])(hierarchy);
    root.each((d) => ((d as any).current = d));

    // Create arc generator
    const arc = d3
      .arc<d3.HierarchyRectangularNode<SunburstData>>()
      .startAngle((d) => d.x0)
      .endAngle((d) => d.x1)
      .padAngle((d) => Math.min((d.x1 - d.x0) / 2, 0.005))
      .padRadius(radius * 1.5)
      .innerRadius((d) => d.y0 * radius)
      .outerRadius((d) => Math.max(d.y0 * radius, d.y1 * radius - 1));

    // Select the SVG container
    const svg = d3
      .select(chartRef.current)
      .attr('viewBox', [-width / 2, -height / 2, width, height].join(' '))
      .style('font', '10px sans-serif');

    svg.selectAll('*').remove(); // Clear previous chart

    // Append arcs
    const path = svg
      .append('g')
      .selectAll('path')
      .data(root.descendants().slice(1))
      .join('path')
      .attr('fill', (d) => {
        return color(d.data.name) as string;
      })
      .attr('fill-opacity', (d: any) =>
        arcVisible(d.current) ? (d.children ? 0.6 : 0.4) : 0,
      )
      .attr('pointer-events', (d: any) =>
        arcVisible(d.current) ? 'auto' : 'none',
      )
      .attr('d', (d: any) => arc(d.current) as string);

    // Make them clickable if they have children.
    path
      .filter((d: any) => d.children)
      .style('cursor', 'pointer')
      .on('click', clicked);

    path
      .append('title')
      .text((d: any) =>
        d.depth === 1
          ? `Site:${d.data.name} Devices:${d.data.devices}`
          : d.depth === 2
          ? `Site:${d.parent.data.name} Protocol:${d.data.name} Devices:${d.data.devices}`
          : `Protocol:${d.parent.data.name} Device:${d.data.name}`,
      );

    // Append labels
    const label = svg
      .append('g')
      .attr('pointer-events', 'none')
      .attr('text-anchor', 'middle')
      .style('user-select', 'none')
      .selectAll('text')
      .data(root.descendants().slice(1))
      .join('text')
      .attr('dy', '0.35em')
      .attr('fill-opacity', (d: any) => +labelVisible(d.current))
      .attr('transform', (d: any) => labelTransform(d.current))
      .text((d) => d.data.name)
      .style('fill', textColor);

    // Append the center circle
    const parent = svg
      .append('circle')
      .datum(root)
      .attr('r', radius)
      .attr('fill', 'none')
      .attr('pointer-events', 'all')
      .style('cursor', 'default')
      .on('click', (event, d) => clicked(event, d));

    // Click handler for zooming
    function clicked(event: any, p: any) {
      parent.datum(p.parent || root);
      parent.style('cursor', p.parent ? 'pointer' : 'default');
      root.each((d) => {
        (d as any).target = {
          x0:
            Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) *
            2 *
            Math.PI,
          x1:
            Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) *
            2 *
            Math.PI,
          y0: Math.max(0, d.y0 - p.depth),
          y1: Math.max(0, d.y1 - p.depth),
        };
      });

      const t = svg.transition().duration(event.altKey ? 7500 : 750);

      path
        .transition(t as any)
        .tween('data', (d) => {
          const i = d3.interpolate((d as any).current, (d as any).target);
          return (t) => ((d as any).current = i(t));
        })
        .filter(function (d: any) {
          const fillOpacity = (this as unknown as SVGPathElement).getAttribute(
            'fill-opacity',
          );
          return (!!fillOpacity && +fillOpacity > 0) || arcVisible(d.target);
        })
        .attr('fill-opacity', (d: any) =>
          arcVisible(d.target) ? (d.children ? 0.6 : 0.4) : 0,
        )
        .attr('pointer-events', (d: any) =>
          arcVisible(d.target) ? 'auto' : 'none',
        )
        .attrTween('d', (d: any) => () => arc(d.current) as string);

      label
        .filter(function (d: any) {
          const fillOpacity = (this as SVGPathElement).getAttribute(
            'fill-opacity',
          );
          return (!!fillOpacity && +fillOpacity > 0) || labelVisible(d.target);
        })
        .transition(t as any)
        .attr('fill-opacity', (d: any) => +labelVisible(d.target))
        .attrTween('transform', (d: any) => () => labelTransform(d.current));
    }

    // Helper functions
    function arcVisible(d: any) {
      return d.y1 <= 3 && d.y0 >= 1 && d.x1 > d.x0;
    }

    function labelVisible(d: any) {
      return d.y1 <= 3 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.03;
    }

    function labelTransform(d: any) {
      const x = ((d.x0 + d.x1) / 2) * (180 / Math.PI);
      const y = ((d.y0 + d.y1) / 2) * radius;
      return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
    }
  }, [devices, sites, themeMode, width]);

  return <svg ref={chartRef}></svg>;
};

export default ZoomableSunburst;
