import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { LogEvent } from './types';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import FormLabel from '@mui/material/FormLabel';
import LogEventsBarChart from './LogEventsBarChart';
import LogEventsLineChart from './LogEventsLineChart';
// import SunburstChart from './SunburstChart';
import TreeChart from './TreeChart';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/Store';
import ZoomableSunburst from './ZoomableSunburst';

type LogsChartProps = {
  chartsData: LogEvent[];
};

const LogEventsChart: React.FC<LogsChartProps> = ({ chartsData }) => {
  const themeMode = useSelector(
    (state: RootState) => state.themeState.themeData,
  ).mode;

  const [chartType, setChartType] = useState('bar');
  const sites = [
    { siteId: '123', name: 'Test Site 1' },
    { siteId: '1234', name: 'Test Site 2' },
  ];
  const devices = [
    {
      deviceId: '1',
      name: 'Test Device 1',
      siteId: '123',
      connectionProfiles: [{ protocol: 'SSH' }, { protocol: 'HTTPS' }],
    },
    {
      deviceId: '2',
      name: 'Test Device 2',
      siteId: '123',
      connectionProfiles: [{ protocol: 'SSH' }, { protocol: 'RDP' }],
    },
    {
      deviceId: '3',
      name: 'Test Device 3',
      siteId: '123',
      connectionProfiles: [{ protocol: 'RDP' }, { protocol: 'HTTPS' }],
    },
    {
      deviceId: '5',
      name: 'Test Device 5',
      siteId: '123',
      connectionProfiles: [
        { protocol: 'RDP' },
        { protocol: 'HTTPS' },
        { protocol: 'SSH' },
      ],
    },
    {
      deviceId: '6',
      name: 'Test Device 6',
      siteId: '123',
      connectionProfiles: [{ protocol: 'SSH' }, { protocol: 'HTTPS' }],
    },
    {
      deviceId: '4',
      name: 'Test Device 4',
      siteId: '1234',
      connectionProfiles: [{ protocol: 'RDP' }, { protocol: 'SSH' }],
    },
  ];
  const handleOptionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    setChartType(value);
  };
  return (
    <>
      <Grid container>
        <FormLabel component="label" sx={{ paddingTop: 1, marginRight: 1 }}>
          <Typography variant="body1"> Select Chart Type: </Typography>
        </FormLabel>
        <RadioGroup
          row
          name="chart-options"
          value={chartType}
          onChange={handleOptionChange}
          sx={{ color: (theme) => theme.palette.info.main }}>
          <FormControlLabel
            value={'bar'}
            control={
              <Radio
                size="small"
                sx={{ color: (theme) => theme.palette.info.main }}
              />
            }
            label={<Typography variant="body2">Bar Chart</Typography>}
          />
          <FormControlLabel
            value={'line'}
            control={
              <Radio
                size="small"
                sx={{ color: (theme) => theme.palette.info.main }}
              />
            }
            label={<Typography variant="body2">Line Chart</Typography>}
          />
        </RadioGroup>
        {chartType === 'bar' ? (
          <LogEventsBarChart
            chartsData={chartsData}
            themeMode={themeMode}></LogEventsBarChart>
        ) : (
          <LogEventsLineChart
            chartsData={chartsData}
            themeMode={themeMode}></LogEventsLineChart>
        )}
      </Grid>
      <Grid container spacing={2} paddingBottom={1}>
        <Grid item xs={4}>
          <ZoomableSunburst
            sites={sites}
            devices={devices}
            width={600}
            themeMode={themeMode}
          />
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={7}>
          <TreeChart
            sites={sites}
            devices={devices}
            themeMode={themeMode}></TreeChart>
        </Grid>
      </Grid>
    </>
  );
};

export default LogEventsChart;
