import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import AccountSettingsPage from './AccountSettings';

type AccountSettingsDialogueProps = {
  open: boolean;
  onCancel: () => void;
};

const AccountSettingsDialogue: React.FC<AccountSettingsDialogueProps> = (
  props,
) => {
  return (
    <React.Fragment>
      <Dialog open={props.open} fullWidth maxWidth="md">
        <DialogTitle sx={{ m: 0, pb: 0 }}>Account Settings</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={props.onCancel}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <AccountSettingsPage />
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onCancel} color="info" variant="outlined">
            {'Close'}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default AccountSettingsDialogue;
