import React, { useEffect, useState } from 'react';
import {
  CssBaseline,
  Button,
  Typography,
  IconButton,
  Box,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import GroupAdd from '@mui/icons-material/GroupAdd';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import apiClient from 'common/apiClientAxios';
import { setLoader, setSnackbarToast } from 'redux/UiStateSlice';
import { Site, SiteFormMasterData, State, Timezone } from '../types';
import { constants } from '../../../common/constants';
import { useDrawerFormStyles } from 'common/styles/useDrawerFormStyles';
import TcAutocomplete from '../../../common/components/TcAutocomplete';
import TcSelectDropdown from 'common/components/TcSelectDropdown';
import TcTextField from 'common/components/TcTextField';
import TcSelectWithButtonLastOption from 'common/components/TcSelectWithButtonLastOption';
import { hasPermission } from 'common/helpers/utils';
import timezones from 'assets/staticdata/timezones.json';
import TcTooltip from 'common/components/TcTooltip';

type AddSiteProps = {
  onClose: () => void;
  onSaveUpdateTable: () => void;
  siteFormMasterData: SiteFormMasterData;
  openOperatorForm: () => void;
  openOwnerForm: () => void;
};

const AddSite: React.FC<AddSiteProps> = (props) => {
  const classes = useDrawerFormStyles();
  const dispatch = useDispatch();
  const { groups, siteTypes, siteOwners, siteOperators, countries } =
    props.siteFormMasterData;

  const [states, setStates] = useState<State[]>([]);
  const [selectedState, setSelectedState] = useState<State>({
    stateId: '',
    code: '',
    countryId: '',
    countryName: '',
    name: '',
  });

  const [selectedZone, setSelectedZone] = useState<Timezone>({
    label: '',
    value: '',
  });

  const onClose = () => {
    props.onClose();
  };

  const methods = useForm<Site>({
    mode: 'onBlur',
  });
  const {
    handleSubmit,
    setValue,
    watch,
    formState: { isValid },
  } = methods;

  const onSaveSite = handleSubmit(async (data: Site) => {
    const country = countries.find(
      (thisCountry) => thisCountry.countryId === data.countryId,
    )?.name;
    const groupName = groups.find(
      (thisGroup) => thisGroup.groupId === data.groupId,
    )?.name;
    const operatorName = siteOperators.find(
      (thisOperator) => thisOperator.operatorId === data.operatorId,
    )?.name;
    const ownerName = siteOwners.find(
      (thisOwner) => thisOwner.ownerId === data.ownerId,
    )?.name;
    const type = siteTypes.find(
      (thisType) => thisType.typeId === data.typeId,
    )?.name;
    const min = parseInt(data.minInboundPort);
    const max = parseInt(data.maxInboundPort);
    const numbersRange = Array.from(
      { length: max - min + 1 },
      (_, index) => min + index,
    );
    try {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        }),
      );
      const siteResponse = await apiClient.post(`/sites`, {
        ...data,
        country,
        state: selectedState.name,
        groupName,
        powerProductionUnit: constants.POWER_PRODUCTION_UNIT,
        operatorName,
        ownerName,
        type,
        allInboundPorts: numbersRange,
        isGuacdConfig: process.env.REACT_APP_SITE_GUACD_CONFIG ?? 'No',
      });
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        }),
      );
      dispatch(
        setSnackbarToast({
          message: siteResponse.data.meta.message,
          open: true,
          severity: 'success',
        }),
      );
    } catch (error: any) {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        }),
      );
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: 'error',
        }),
      );
    }
    props.onSaveUpdateTable();
  });

  const watchedCountryValue = watch('countryId');

  const onStateSelect = (_event: any, value: State | null) => {
    if (value) {
      setSelectedState(value);
      setValue('stateId', value.stateId, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  };

  const onTimezoneSelect = (_event: any, value: Timezone | null) => {
    if (value) {
      setSelectedZone(value);
      setValue('timezone', value.value, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  };

  useEffect(() => {
    const fetchStates = async () => {
      if (watchedCountryValue) {
        dispatch(
          setLoader({
            loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
            openLoader: true,
          }),
        );
        try {
          const response = await apiClient.get(
            `/countries/${watchedCountryValue}/states`,
          );
          setStates(response.data.data);
          dispatch(
            setLoader({
              loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
              openLoader: false,
            }),
          );
        } catch (error: any) {
          dispatch(
            setLoader({
              loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
              openLoader: false,
            }),
          );
          const errorData =
            error.response?.data?.meta?.message || String(error.message);
          dispatch(
            setSnackbarToast({
              message: errorData,
              open: true,
              severity: 'error',
            }),
          );
        }
      }
    };
    fetchStates();
  }, [dispatch, watchedCountryValue]);

  const handleCreateOperator = () => {
    props.openOperatorForm();
  };

  const handleCreateOwner = () => {
    props.openOwnerForm();
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Grid container className={classes.formTitleContainer}>
        <Grid item xs={11}>
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <Typography component="h6" variant="h6" gutterBottom>
              {'Add Site'}
            </Typography>
            {process.env.REACT_APP_SHOW_INFO_TOOLTIP?.toLowerCase() ===
              'yes' && (
              <TcTooltip
                placement="bottom-start"
                title={
                  <React.Fragment>
                    <Typography>Inbound IP and Port Range:</Typography>
                    {constants.INBOUND_PORT_INFO}
                    <br />
                    {constants.INBOUND_INFO}
                    {process.env.REACT_APP_SITE_GUACD_CONFIG?.toLowerCase() ===
                      'yes' && (
                      <>
                        <br />
                        <br />
                        <Typography>Guacd IP and Port:</Typography>
                        {constants.GAUCD_INFO}
                        <br />
                        {constants.WEBSOCKET_URL_INFO}
                      </>
                    )}
                  </React.Fragment>
                }>
                <IconButton color="info">
                  <InfoOutlinedIcon fontSize="inherit" />
                </IconButton>
              </TcTooltip>
            )}
          </Box>
        </Grid>
        <Grid item xs={1}>
          <IconButton
            onClick={props.onClose}
            sx={{
              position: 'absolute',
              right: 8,
              color: (theme) => theme.palette.grey[700],
            }}>
            <CloseOutlinedIcon />
          </IconButton>
        </Grid>
      </Grid>
      <FormProvider {...methods}>
        <form noValidate>
          <Grid container spacing={2} className={classes.formContainer}>
            <Grid item xs={6}>
              <TcTextField
                name="name"
                label="Name *"
                rules={{
                  required: 'Name is required',
                  pattern: {
                    value: constants.SITE_NAME_REGEX,
                    message: 'Invalid name',
                  },
                }}></TcTextField>
            </Grid>
            <Grid item xs={6}>
              <TcTextField
                name="alias"
                label="Alias (Nickname)"
                rules={{
                  pattern: {
                    value: constants.NAME_REGEX,
                    message: 'Invalid Alias',
                  },
                }}></TcTextField>
            </Grid>
            <Grid item xs={6}>
              <TcSelectWithButtonLastOption
                name="ownerId"
                label="Owner *"
                optionKey="ownerId"
                options={siteOwners}
                optionLabel="name"
                buttonIcon={<GroupAdd />}
                buttonLabel="Add owner"
                optionButtonClick={handleCreateOwner}
                optionButtonDisabled={!hasPermission('owners', 'write')}
                rules={{
                  required: 'Owner is required',
                }}></TcSelectWithButtonLastOption>
            </Grid>
            <Grid item xs={6}>
              <TcSelectWithButtonLastOption
                name="operatorId"
                label="Operator"
                optionKey="operatorId"
                options={siteOperators}
                optionLabel="name"
                buttonIcon={<GroupAdd />}
                buttonLabel="Add operator"
                optionButtonClick={handleCreateOperator}
                optionButtonDisabled={
                  !hasPermission('operators', 'write')
                }></TcSelectWithButtonLastOption>
            </Grid>
            <Grid item xs={6}>
              <TcTextField
                name="inboundIpAddr"
                label="Inbound IP Address *"
                rules={{
                  required: 'Inbound IP Address is required',
                  pattern: {
                    value: constants.IP_ADDRESS_REGEX,
                    message: 'Invalid IP',
                  },
                }}></TcTextField>
            </Grid>
            <Grid item xs={3}>
              <TcTextField
                name="minInboundPort"
                label="Min Inbound port *"
                type="number"
                inputProps={{ min: 1 }}
                rules={{
                  required: 'Port is required',
                  validate: (value: number) =>
                    value > 0 || 'Port must be greater than 0',
                }}></TcTextField>
            </Grid>
            <Grid item xs={3}>
              <TcTextField
                name="maxInboundPort"
                label="Max Inbound port *"
                type="number"
                inputProps={{ min: 1 }}
                rules={{
                  required: 'Port is required',
                  validate: (value: number) =>
                    value > 0 || 'Port must be greater than 0',
                }}></TcTextField>
            </Grid>
            {process.env.REACT_APP_SITE_GUACD_CONFIG?.toLowerCase() ===
              'yes' && (
              <>
                <Grid item xs={6}>
                  <TcTextField
                    name="guacdIpAddr"
                    label="Site Guacd IP Address *"
                    rules={{
                      required: 'Site Guacd IP Address is required',
                      pattern: {
                        value: constants.IP_ADDRESS_REGEX,
                        message: 'Invalid IP',
                      },
                    }}></TcTextField>
                </Grid>
                <Grid item xs={6}>
                  <TcTextField
                    name="guacdPort"
                    label="Site Guacd port *"
                    type="number"
                    inputProps={{ min: 1 }}
                    rules={{
                      required: 'Site Guacd Port is required',
                      validate: (value: number) =>
                        value > 0 || 'Port must be greater than 0',
                    }}></TcTextField>
                </Grid>
              </>
            )}
            <Grid item xs={6}>
              <TcTextField
                name="addressLine"
                label="Address *"
                rules={{
                  required: 'Address is required',
                }}></TcTextField>
            </Grid>
            <Grid item xs={6}>
              <TcSelectDropdown
                name="countryId"
                label="Country *"
                options={countries}
                optionKey="countryId"
                optionLabel="name"
                rules={{ required: 'Country is required' }}
              />
            </Grid>
            <Grid item xs={6}>
              <TcAutocomplete
                name="stateId"
                label="State"
                optionKey="stateId"
                options={states}
                value={selectedState}
                onValueSelect={onStateSelect}
                getOptionLabel={(state) => state.name}></TcAutocomplete>
            </Grid>
            <Grid item xs={6}>
              <TcTextField
                name="city"
                label="City *"
                rules={{
                  required: 'City is required',
                }}></TcTextField>
            </Grid>

            <Grid item xs={6}>
              <TcAutocomplete
                name="timezone"
                label="Timezone *"
                options={timezones as Timezone[]}
                optionKey={'value'}
                value={selectedZone}
                onValueSelect={onTimezoneSelect}
                getOptionLabel={(zone) => zone.label}
                rules={{
                  required: 'Timezone is required',
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TcTextField
                name="latitude"
                label="Latitude"
                rules={{
                  pattern: {
                    value: constants.LATITUDE_REGEX,
                    message: 'Invalid latitude format',
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TcTextField
                name="longitude"
                label="Longitude"
                rules={{
                  pattern: {
                    value: constants.LONGITUDE_REGEX,
                    message: 'Invalid longitude format',
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TcTextField
                name="zipcode"
                label="Zipcode"
                rules={{
                  pattern: {
                    value: constants.ZIP_CODE_REGEX,
                    message: 'Invalid zipcode',
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TcTextField name="contactName" label="Contact Name" />
            </Grid>
            <Grid item xs={6}>
              <TcTextField
                name="contactPhone"
                label="Contact Phone"
                rules={{
                  pattern: {
                    value: constants.PHONE_REGEX,
                    message: 'Invalid contact number',
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TcTextField
                name="contactEmail"
                label="Contact Email"
                rules={{
                  pattern: {
                    value: constants.MAIL_REGEX,
                    message: 'Invalid email',
                  },
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TcTextField name="contactCompany" label="Contact Company" />
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="info"
                component="label"
                disabled>
                Choose Site Image
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: 'none' }}
                  disabled
                />
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="info"
                component="label"
                disabled>
                Choose Layout Image
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: 'none' }}
                  disabled
                />
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.bottomDivider}>
            <Grid item xs={2}>
              <Button
                size="small"
                type="submit"
                disabled={!isValid}
                onClick={onSaveSite}
                fullWidth
                variant="contained"
                color="info">
                {'Save'}
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                size="small"
                fullWidth
                variant="outlined"
                color="info"
                onClick={onClose}>
                {'Cancel'}
              </Button>
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
        </form>
      </FormProvider>
    </React.Fragment>
  );
};

export default AddSite;
