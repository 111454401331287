import useAuth from 'pages/login/authService';
import React from 'react';

const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { logout } = useAuth();
  const sessionUser = sessionStorage.getItem('user')
    ? sessionStorage.getItem('user')
    : localStorage.getItem('user');

  const accessToken = sessionStorage.getItem('access_token')
    ? sessionStorage.getItem('access_token')
    : localStorage.getItem('access_token');

  const isAuthenticated = !!accessToken && !!sessionUser;
  if (!isAuthenticated) {
    logout();
  }
  return children;
};
export default ProtectedRoute;
