import React, { useEffect, useState } from 'react';

import {
  CssBaseline,
  Button,
  Breadcrumbs,
  Card,
  CardContent,
  Typography,
  CardActionArea,
  IconButton,
  useTheme,
  Box,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import apiClient from 'common/apiClientAxios';
import { setLoader, setSnackbarToast } from 'redux/UiStateSlice';
import { FormMasterData, Rule, RuleForm } from '../types';
import RuleScheduleForm from './RuleScheduleForm';
import { constants } from 'common/constants';
import TcSelectWithStringArray from 'common/components/TcSelectWithStringArray';
import { DeviceGroup } from 'pages/devices/types';
import { UserGroup } from 'pages/users/types';
import TcAutocomplete from 'common/components/TcAutocomplete';
import {
  getAccessWindowDisplaySetting,
  getDateRangeAccessWindow,
  getDateWithTime,
  getDayInfo,
  getRepeatingAccessWindow,
  getTimezone,
} from 'common/helpers/utils';
import { useContentStyles } from 'common/styles/useContentStyles';
import TcTextField from 'common/components/TcTextField';
import { AccessWindowType, RecurrenceUnit, RuleTemplate } from 'common/enums';
import { splitByBreakTag } from './AddRule';
import TcTooltip from 'common/components/TcTooltip';
import { RootState } from 'redux/Store';

const EditRule: React.FC = () => {
  const classes = useContentStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { userData } = useSelector((state: RootState) => state.userState);
  const accessWindowDisplay = getAccessWindowDisplaySetting(
    userData.accessWindowDisplay,
  );
  const { formMasterData, selectedRule } = location.state as {
    formMasterData: FormMasterData;
    selectedRule: Rule;
  };
  const onClose = () => {
    navigate(`/portal/trust/list/`);
  };
  const { userGroups, deviceGroups } = formMasterData;
  const [selectedUserGroup, setSelectedUserGroup] = useState<UserGroup>({
    groupId: selectedRule.userGroupId,
    name: selectedRule.userGroupName,
  });
  const [selectedDeviceGroup, setSelectedDeviceGroup] = useState<DeviceGroup>({
    groupId: selectedRule.deviceGroupId,
    name: selectedRule.deviceGroupName,
  });
  const [openCalendarModal, setOpenCalendarModal] = React.useState(false);
  const [accessWindow, setAccessWindow] = React.useState('');

  useEffect(() => {
    setAccessWindow(
      selectedRule.accessWindowType === AccessWindowType.DATE_RANGE
        ? getDateRangeAccessWindow({
            startDate: selectedRule.startDate,
            endDate: selectedRule.endDate,
            fromTime: selectedRule.startTime,
            toTime: selectedRule.endTime,
            daysTemplate: selectedRule.daysTemplate as RuleTemplate,
            schedule: selectedRule.schedule,
            userZone: accessWindowDisplay.zone,
            timeSelectionType: selectedRule.timeSelectionType as RuleTemplate,
          })
        : getRepeatingAccessWindow({
            startDate: selectedRule.recurrenceStartDate,
            endDate: selectedRule.recurrenceEndDate,
            fromTime: selectedRule.recurrenceStartTime,
            toTime: selectedRule.recurrenceEndTime,
            schedule: selectedRule.recurrenceWeekSchedule,
            recurrence: selectedRule.recurrence ?? '1',
            recurrenceUnit: selectedRule.recurrenceUnit,
            recurrenceMonth: selectedRule.recurrenceMonth,
            userZone: accessWindowDisplay.zone,
          }),
    );
  }, [
    accessWindowDisplay.zone,
    selectedRule.accessWindowType,
    selectedRule.daysTemplate,
    selectedRule.endDate,
    selectedRule.endTime,
    selectedRule.recurrence,
    selectedRule.recurrenceEndDate,
    selectedRule.recurrenceEndTime,
    selectedRule.recurrenceMonth,
    selectedRule.recurrenceStartDate,
    selectedRule.recurrenceStartTime,
    selectedRule.recurrenceUnit,
    selectedRule.recurrenceWeekSchedule,
    selectedRule.schedule,
    selectedRule.startDate,
    selectedRule.startTime,
    selectedRule.timeSelectionType,
  ]);

  dayjs.extend(utc);
  dayjs.extend(timezone);
  const methods = useForm<RuleForm>({
    defaultValues: {
      ...selectedRule,
      accessWindowType:
        selectedRule.accessWindowType ?? AccessWindowType.DATE_RANGE,
      startDate: selectedRule.startDate ? dayjs(selectedRule.startDate) : null,
      endDate: selectedRule.endDate ? dayjs(selectedRule.endDate) : null,
      startTime: selectedRule.startTime ? dayjs(selectedRule.startTime) : null,
      endTime: selectedRule.endTime ? dayjs(selectedRule.endTime) : null,
      daysTemplate: selectedRule.daysTemplate ?? RuleTemplate.ALL_DAYS,
      recurrenceStartDate: selectedRule.recurrenceStartDate
        ? dayjs(selectedRule.recurrenceStartDate)
        : null,
      recurrenceEndDate: selectedRule.recurrenceEndDate
        ? dayjs(selectedRule.recurrenceEndDate)
        : null,
      recurrenceStartTime: selectedRule.recurrenceStartTime
        ? dayjs(selectedRule.recurrenceStartTime)
        : null,
      recurrenceEndTime: selectedRule.recurrenceEndTime
        ? dayjs(selectedRule.recurrenceEndTime)
        : null,
      schedule: {
        Sunday: {
          startTime: selectedRule.schedule?.Sunday.startTime
            ? dayjs(selectedRule.schedule?.Sunday.startTime)
            : null,
          endTime: selectedRule.schedule?.Sunday.endTime
            ? dayjs(selectedRule.schedule?.Sunday.endTime)
            : null,
          isActive: selectedRule.schedule?.Sunday.isActive ?? false,
        },
        Monday: {
          startTime: selectedRule.schedule?.Monday.startTime
            ? dayjs(selectedRule.schedule?.Monday.startTime)
            : null,
          endTime: selectedRule.schedule?.Monday.endTime
            ? dayjs(selectedRule.schedule?.Monday.endTime)
            : null,
          isActive: selectedRule.schedule?.Monday.isActive ?? false,
        },
        Tuesday: {
          startTime: selectedRule.schedule?.Tuesday.startTime
            ? dayjs(selectedRule.schedule?.Tuesday.startTime)
            : null,
          endTime: selectedRule.schedule?.Tuesday.endTime
            ? dayjs(selectedRule.schedule?.Tuesday.endTime)
            : null,
          isActive: selectedRule.schedule?.Tuesday.isActive ?? false,
        },
        Wednesday: {
          startTime: selectedRule.schedule?.Wednesday.startTime
            ? dayjs(selectedRule.schedule?.Wednesday.startTime)
            : null,
          endTime: selectedRule.schedule?.Wednesday.endTime
            ? dayjs(selectedRule.schedule?.Wednesday.endTime)
            : null,
          isActive: selectedRule.schedule?.Wednesday.isActive ?? false,
        },
        Thursday: {
          startTime: selectedRule.schedule?.Thursday.startTime
            ? dayjs(selectedRule.schedule?.Thursday.startTime)
            : null,
          endTime: selectedRule.schedule?.Thursday.endTime
            ? dayjs(selectedRule.schedule?.Thursday.endTime)
            : null,
          isActive: selectedRule.schedule?.Thursday.isActive ?? false,
        },
        Friday: {
          startTime: selectedRule.schedule?.Friday.startTime
            ? dayjs(selectedRule.schedule?.Friday.startTime)
            : null,
          endTime: selectedRule.schedule?.Friday.endTime
            ? dayjs(selectedRule.schedule?.Friday.endTime)
            : null,
          isActive: selectedRule.schedule?.Friday.isActive ?? false,
        },
        Saturday: {
          startTime: selectedRule.schedule?.Saturday.startTime
            ? dayjs(selectedRule.schedule?.Saturday.startTime)
            : null,
          endTime: selectedRule.schedule?.Saturday.endTime
            ? dayjs(selectedRule.schedule?.Saturday.endTime)
            : null,
          isActive: selectedRule.schedule?.Saturday.isActive ?? false,
        },
      },
      recurrenceWeekSchedule: {
        Sunday: {
          isActive:
            selectedRule.recurrenceWeekSchedule?.Sunday.isActive ?? false,
        },
        Monday: {
          isActive:
            selectedRule.recurrenceWeekSchedule?.Monday.isActive ?? false,
        },
        Tuesday: {
          isActive:
            selectedRule.recurrenceWeekSchedule?.Tuesday.isActive ?? false,
        },
        Wednesday: {
          isActive:
            selectedRule.recurrenceWeekSchedule?.Wednesday.isActive ?? false,
        },
        Thursday: {
          isActive:
            selectedRule.recurrenceWeekSchedule?.Thursday.isActive ?? false,
        },
        Friday: {
          isActive:
            selectedRule.recurrenceWeekSchedule?.Friday.isActive ?? false,
        },
        Saturday: {
          isActive:
            selectedRule.recurrenceWeekSchedule?.Saturday.isActive ?? false,
        },
      },
    },
    mode: 'onBlur',
  });

  const {
    handleSubmit,
    getValues,
    setValue,
    formState: { isValid, errors, isDirty },
  } = methods;
  const onCancelCalendarModal = () => {
    if (getValues('accessWindowType') === AccessWindowType.DATE_RANGE) {
      setValue(
        'startDate',
        selectedRule.startDate ? dayjs(selectedRule.startDate) : null,
      );
      setValue(
        'endDate',
        selectedRule.endDate ? dayjs(selectedRule.endDate) : null,
      );
      setValue(
        'startTime',
        selectedRule.startTime ? dayjs(selectedRule.startTime) : null,
      );
      setValue(
        'endTime',
        selectedRule.endTime ? dayjs(selectedRule.endTime) : null,
      );
      setValue('daysTemplate', selectedRule.daysTemplate);
    } else {
      setValue(
        'recurrenceStartDate',
        selectedRule.recurrenceStartDate
          ? dayjs(selectedRule.recurrenceStartDate)
          : null,
      );
      setValue(
        'recurrenceEndDate',
        selectedRule.recurrenceEndDate
          ? dayjs(selectedRule.recurrenceEndDate)
          : null,
      );
      setValue(
        'recurrenceStartTime',
        selectedRule.recurrenceStartTime
          ? dayjs(selectedRule.recurrenceStartTime)
          : null,
      );
      setValue(
        'recurrenceEndTime',
        selectedRule.recurrenceEndTime
          ? dayjs(selectedRule.recurrenceEndTime)
          : null,
      );
      setValue('recurrenceUnit', selectedRule.recurrenceUnit);
      setValue('recurrence', selectedRule.recurrence);
      setValue('recurrenceMonth', selectedRule.recurrenceMonth);
      setValue('recurrenceWeekSchedule', selectedRule.recurrenceWeekSchedule);
    }
    setAccessWindow(selectedRule.accessWindow ?? '');
    setOpenCalendarModal(false);
  };

  const onAcceptCalendar = () => {
    if (getValues('accessWindowType') === AccessWindowType.DATE_RANGE) {
      setAccessWindow(
        getDateRangeAccessWindow({
          startDate: getValues('startDate'),
          endDate: getValues('endDate'),
          fromTime: getValues('startTime'),
          toTime: getValues('endTime'),
          daysTemplate: getValues('daysTemplate') as RuleTemplate,
          schedule: getValues('schedule'),
          userZone: accessWindowDisplay.zone,
          timeSelectionType: getValues('timeSelectionType') as RuleTemplate,
        }),
      );
    } else {
      setAccessWindow(
        getRepeatingAccessWindow({
          startDate: getValues('recurrenceStartDate'),
          endDate: getValues('recurrenceEndDate'),
          fromTime: getValues('recurrenceStartTime'),
          toTime: getValues('recurrenceEndTime'),
          schedule: getValues('recurrenceWeekSchedule'),
          recurrence: getValues('recurrence') ?? '1',
          recurrenceUnit: getValues('recurrenceUnit'),
          recurrenceMonth: getValues('recurrenceMonth'),
          userZone: accessWindowDisplay.zone,
        }),
      );
    }

    setOpenCalendarModal(false);
  };

  const onSelectUserGroup = (
    _event: React.SyntheticEvent<Element, Event>,
    value: UserGroup | null,
  ) => {
    if (value) {
      setValue('userGroupId', value.groupId, { shouldDirty: true });
      setSelectedUserGroup(value);
    }
  };

  const onSelectDeviceGroup = (
    _event: React.SyntheticEvent<Element, Event>,
    value: DeviceGroup | null,
  ) => {
    if (value) {
      setValue('deviceGroupId', value.groupId, { shouldDirty: true });
      setSelectedDeviceGroup(value);
    }
  };

  const onUpdate = handleSubmit(async (data: RuleForm) => {
    try {
      const ruleId = selectedRule.ruleId;
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        }),
      );
      let recurrenceMonth = {};
      if (
        data.recurrenceMonth?.option &&
        data.recurrenceUnit === RecurrenceUnit.MONTHS
      ) {
        const dateInfo = getDayInfo(data.recurrenceStartDate);
        recurrenceMonth = {
          dayOfMonth: dateInfo.dayOfMonth,
          dayName: dateInfo.dayName,
          positionInMonth: dateInfo.positionInMonth,
          option: data.recurrenceMonth.option,
          weekNumber: dateInfo.weekNumber,
        };
      }
      const startDateWithTime = getDateWithTime(data.startDate, data.startTime);
      const endDateWithTime = getDateWithTime(data.endDate, data.endTime);
      const recurrenceStartDateWithTime = getDateWithTime(
        data.recurrenceStartDate,
        data.recurrenceStartTime,
      );
      const recurrenceEndDateWithTime = getDateWithTime(
        data.recurrenceEndDate,
        data.recurrenceEndTime,
      );
      if (data.schedule) {
        const { schedule } = data;
        constants.DAYS.forEach((day) => {
          if (schedule[day].startTime) {
            schedule[day].startTime = getDateWithTime(
              data.startDate,
              schedule[day].startTime,
            );
          }
          if (schedule[day].endTime) {
            schedule[day].endTime = getDateWithTime(
              data.endDate,
              schedule[day].endTime,
            );
          }
        });
      }
      const trustRuleResponse = await apiClient.put(`/trust-rules/${ruleId}`, {
        ...data,
        startDate: startDateWithTime,
        endDate: endDateWithTime,
        startTime: data.startTime ? startDateWithTime : null,
        endTime: data.endTime ? endDateWithTime : null,
        recurrenceStartDate: recurrenceStartDateWithTime,
        recurrenceEndDate: recurrenceEndDateWithTime,
        recurrenceStartTime: data.recurrenceStartTime
          ? recurrenceStartDateWithTime
          : null,
        recurrenceEndTime: data.recurrenceEndTime
          ? recurrenceEndDateWithTime
          : null,
        ruleId,
        userGroupName: selectedUserGroup?.name,
        deviceGroupName: selectedDeviceGroup?.name,
        zoneId: data.zone?.zoneId,
        zoneName: data.zone?.name,
        accessWindow,
        recurrenceMonth: { ...recurrenceMonth },
        timezone: getTimezone(),
      });
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        }),
      );
      dispatch(
        setSnackbarToast({
          message: trustRuleResponse.data.meta.message,
          open: true,
          severity: 'success',
        }),
      );
    } catch (error: any) {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        }),
      );
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: 'error',
        }),
      );
    }
    navigate(`/portal/trust/list/`);
  });
  const isNameExists = (name: string) => {
    return formMasterData.rules.some(
      (rule) =>
        rule.name.toLowerCase() === name.toLowerCase() &&
        selectedRule.name.toLowerCase() !== name.toLowerCase(),
    );
  };
  const theme = useTheme();
  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.contentPadding}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            to="/portal/trust/list"
            style={{
              color: theme.palette.text.secondary,
            }}>
            Trust Rules
          </Link>
          <Typography variant="body1">{selectedRule.name}</Typography>
        </Breadcrumbs>
      </div>
      <Card
        elevation={0}
        sx={{
          padding: 2,
        }}>
        <FormProvider {...methods}>
          <form noValidate>
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <Typography component="h6" variant="h6" gutterBottom>
                  {`Edit Rule`}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Box
                  sx={{
                    float: 'right',
                    display: 'flex',
                    alignItems: 'center',
                    color: (theme) => theme.palette.info.main,
                  }}>
                  <Typography>
                    Access window displayed in:{' '}
                    <strong>{accessWindowDisplay.display}</strong>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={10}>
                    <Grid container spacing={2}>
                      <Grid item alignSelf={'center'}>
                        <Typography variant="body1">Rule Name *</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <TcTextField
                          name="name"
                          rules={{
                            required: 'Rule Name is required',
                            pattern: {
                              value: constants.NAME_REGEX,
                              message: 'Invalid name',
                            },
                            validate: {
                              nameExists: (value: string) =>
                                isNameExists(value)
                                  ? 'This rule name is already used'
                                  : true,
                            },
                          }}></TcTextField>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={1}>
                    <Button
                      size="small"
                      type="submit"
                      disabled={
                        !isValid || !isDirty || Object.keys(errors).length > 0
                      }
                      onClick={onUpdate}
                      fullWidth
                      variant="contained"
                      color="info">
                      {'Save'}
                    </Button>
                  </Grid>
                  <Grid item xs={1}>
                    <Button
                      size="small"
                      type="button"
                      fullWidth
                      variant="outlined"
                      color="info"
                      onClick={onClose}>
                      {'Cancel'}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2} alignItems="stretch">
                  <Grid item xs={3}>
                    <Card
                      sx={{
                        backgroundColor: 'secondary.main',
                        width: '100%',
                        height: '100%',
                      }}
                      elevation={0}>
                      <CardContent>
                        <Grid container spacing={2} flexDirection={'column'}>
                          <Grid item alignSelf={'center'}>
                            <strong>
                              <label htmlFor="userGroup">User Group</label>
                            </strong>
                            {process.env.REACT_APP_SHOW_INFO_TOOLTIP?.toLowerCase() ===
                              'yes' && (
                              <TcTooltip title={constants.RULE_USER_GROUP_INFO}>
                                <IconButton size="small" color="primary">
                                  <InfoOutlinedIcon fontSize="inherit" />
                                </IconButton>
                              </TcTooltip>
                            )}
                          </Grid>
                          <Grid item>
                            <Card elevation={0}>
                              <TcAutocomplete
                                name="userGroupId"
                                label=""
                                optionKey="groupId"
                                options={userGroups}
                                value={selectedUserGroup}
                                getOptionLabel={(grp) => grp.name}
                                onValueSelect={onSelectUserGroup}
                                rules={{
                                  required: 'User Group is required',
                                }}></TcAutocomplete>
                            </Card>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={3}>
                    <Card
                      sx={{
                        backgroundColor: 'secondary.main',
                        width: '100%',
                        height: '100%',
                      }}
                      elevation={0}>
                      <CardContent>
                        <Grid container spacing={2} flexDirection={'column'}>
                          <Grid item alignSelf={'center'}>
                            <strong>
                              <label htmlFor="deviceGroup">Device Group</label>
                            </strong>
                            {process.env.REACT_APP_SHOW_INFO_TOOLTIP?.toLowerCase() ===
                              'yes' && (
                              <TcTooltip
                                title={constants.RULE_DEVICE_GROUP_INFO}>
                                <IconButton size="small" color="primary">
                                  <InfoOutlinedIcon fontSize="inherit" />
                                </IconButton>
                              </TcTooltip>
                            )}
                          </Grid>
                          <Grid item>
                            <Card elevation={0}>
                              <TcAutocomplete
                                name="deviceGroupId"
                                label=""
                                optionKey="groupId"
                                options={deviceGroups}
                                value={selectedDeviceGroup}
                                getOptionLabel={(grp) => grp.name}
                                onValueSelect={onSelectDeviceGroup}
                                rules={{
                                  required: 'Device Group is required',
                                }}></TcAutocomplete>
                            </Card>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={3}>
                    <Card
                      sx={{
                        backgroundColor: 'secondary.main',
                        width: '100%',
                        height: '100%',
                      }}
                      elevation={0}>
                      <CardContent>
                        <Grid container spacing={2} flexDirection={'column'}>
                          <Grid item alignSelf={'center'}>
                            <strong>
                              <label htmlFor="localAccess">
                                Local Access Profile
                              </label>
                            </strong>
                          </Grid>
                          <Grid item>
                            <Card elevation={0}>
                              <TcSelectWithStringArray
                                label=""
                                disabled={
                                  !(
                                    selectedDeviceGroup.groupId !== '' &&
                                    selectedUserGroup.groupId !== ''
                                  )
                                }
                                name="accessProfile"
                                options={
                                  constants.CONNECTION_PROFILE_ACCESS_PROFILES
                                }
                                rules={{
                                  required: 'Local Access Profile is required',
                                }}
                              />
                            </Card>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={3}>
                    <Card
                      sx={{
                        backgroundColor: 'secondary.main',
                        width: '100%',
                        height: '100%',
                      }}
                      elevation={0}>
                      <CardContent>
                        <Grid container spacing={2} flexDirection={'column'}>
                          <Grid item alignSelf={'center'}>
                            <strong>
                              <label htmlFor="accessWindow">
                                Access Window
                              </label>
                            </strong>
                          </Grid>
                          <Grid
                            item
                            alignSelf={'center'}
                            xs={12}
                            sx={{ width: '100%' }}>
                            <Grid
                              container
                              spacing={2}
                              flexDirection={'column'}>
                              {accessWindow ? (
                                <Grid
                                  item
                                  alignSelf={'center'}
                                  sx={{ width: '100%' }}>
                                  <Card>
                                    <CardActionArea
                                      onClick={() =>
                                        setOpenCalendarModal(true)
                                      }>
                                      <CardContent style={{ padding: 8 }}>
                                        {' '}
                                        <div data-testid="accessWindowButton">
                                          {splitByBreakTag(accessWindow)}
                                        </div>
                                      </CardContent>
                                    </CardActionArea>
                                  </Card>
                                </Grid>
                              ) : (
                                <Grid item sx={{ width: '100%' }}>
                                  <Card>
                                    <CardContent style={{ padding: 0 }}>
                                      <IconButton
                                        sx={{ paddingLeft: '50%' }}
                                        onClick={() =>
                                          setOpenCalendarModal(true)
                                        }>
                                        <EditCalendarIcon />
                                      </IconButton>
                                    </CardContent>
                                  </Card>
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {openCalendarModal && (
              <RuleScheduleForm
                open={openCalendarModal}
                onCancelCalendarModal={onCancelCalendarModal}
                onAcceptCalendar={onAcceptCalendar}></RuleScheduleForm>
            )}
          </form>
        </FormProvider>
      </Card>
    </React.Fragment>
  );
};

export default EditRule;
