export enum UserStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  PENDING = 'Pending',
}

export enum Recurrence {
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly',
  YEARLY = 'Yearly',
}

export enum DeviceStatus {
  ONLINE = 'Online',
  OFFLINE = 'Offline',
}

export enum DeviceAccessMethod {
  CONFIG = 'config',
  HTTP = 'http',
  HTTPS = 'https',
  RDP = 'rdp',
  SSH = 'ssh',
  HTTP_S = 'http/s',
}

export enum IdentityAccessProvider {
  AZURE = 'Azure',
  OKTA = 'Okta',
  CUSTOM = 'Custom',
  LOCAL_AD = 'Local_AD',
}

export enum IdentityProviderStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export enum FeatureStatus {
  ENABLED = 'Enabled',
  DISABLED = 'Disabled',
}

export enum DeviceGroupType {
  STATIC = 'Static',
  DYNAMIC = 'Dynamic',
}

export enum UserGroupType {
  STATIC = 'Static',
  DYNAMIC = 'Dynamic',
}

export enum DevicePort {
  SSH = '22',
  RDP = '3389',
  HTTP = '80',
  HTTP_S = '443',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  HTTPS = '443',
}

export enum LoginFeature {
  AZURE = 'azure',
  CUSTOM = 'custom',
  LOCAL_AD = 'local_ad',
}

export enum FilterDisplayOption {
  ALL = 'all',
  FAVORITE = 'favorite',
  RECENT = 'recent',
  ACCESS = 'access',
}

export enum RuleStatus {
  ACTIVE = 'Active',
  DISABLED = 'Disabled',
  EXPIRED = 'Expired',
  FUTURE = 'Future',
  INCOMPLETE = 'Incomplete',
  ACCESSIBLE = 'Accessible',
  IN_ACCESSIBLE = 'Inaccessible',
}

export enum RuleTemplate {
  ALL_DAYS = 'All Days',
  WEEK_DAYS = 'Week Days',
  CUSTOM = 'Custom',
  FULL_DAY_TIME = 'Full Day',
}

export enum RecurrenceUnit {
  DAYS = 'Days',
  MONTHS = 'Months',
  WEEKS = 'Weeks',
  YEARS = 'Years',
}

export enum AccessWindowType {
  DATE_RANGE = 'DateRange',
  RECURRENCE = 'Recurrence',
}

export enum PortalType {
  ALL = 'ALL',
  ENDUSER = 'ENDUSER',
}

export enum RemoteAccessType {
  DIALOG = 'dialog',
  TAB = 'newTab',
}

export enum DomainRegistered {
  YES = 'Yes',
  NO = 'No',
  REGISTERED = 'Registered',
}

export enum Protocol {
  CONFIG = 'Config',
  HTTP = 'HTTP',
  HTTPS = 'HTTPS',
  RDP = 'RDP',
  SSH = 'SSH',
  HTTP_S = 'HTTP/S',
}

export enum HostnameType {
  IP = 'IP',
  URL = 'URL',
}

export enum AuthenticationType {
  NO_AUTH = 'no_auth',
  MANUAL = 'manual',
  PROFILE = 'profile',
}

export enum AuthMethod {
  KEY = 'Key',
  PASSWORD = 'Password',
}

export enum DefaultPort {
  RDP = 3389,
  SSH = 22,
  HTTP = 80,
  HTTPS = 443,
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  HTTP_S = 443,
}

export enum AccessWindowDisplayType {
  BROWSER = 'browser',
  DEVICE = 'device',
  UTC = 'UTC',
}

export enum CompareValues {
  YES = 'yes',
  NO = 'no',
  ENABLED = 'enabled',
  SAVE = 'save',
  DELETE = 'delete',
  READ = 'read',
  WRITE = 'write',
  ADMIN = 'admin',
  ENDUSER = 'enduser',
  TRUE = 'true',
  FALSE = 'false',
  ALL = 'all',
  HTTP = 'http',
  HTTP_S = 'http/s',
}

export enum AccessProfile {
  SUPERVISOR = 'Supervisor',
  GUEST = 'Guest',
}
