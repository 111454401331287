import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import React from 'react';
import { DeviceConnectionProfileForm } from '../types';

type DeleteProfileConfirmProps = {
  selectedProfile: DeviceConnectionProfileForm;
  open: boolean;
  onCancelRemove: () => void;
  onConfirmDelete: () => void;
};

const DeleteProfileConfirm: React.FC<DeleteProfileConfirmProps> = ({
  selectedProfile,
  open,
  onCancelRemove,
  onConfirmDelete,
}) => {
  const handleRemove = () => {
    onConfirmDelete();
  };

  return (
    <React.Fragment>
      <Dialog open={open}>
        <DialogTitle>{`Delete profile '${selectedProfile.name}' ?`}</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancelRemove} color="info" variant="outlined">
            {'Cancel'}
          </Button>
          <Button
            onClick={handleRemove}
            color="warning"
            autoFocus
            variant="contained">
            {'Remove'}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default DeleteProfileConfirm;
