import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  CssBaseline,
  Card,
  Link,
  Typography,
  CardHeader,
  Drawer,
  Button,
} from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { useDispatch } from 'react-redux';
import { setSnackbarToast } from 'redux/UiStateSlice';

import { useContentStyles } from 'common/styles/useContentStyles';
import apiClient from 'common/apiClientAxios';
import AddOperator from './AddOperator';
import DeleteOperator from './DeleteOperator';
import EditOperator from './EditOperator';
import OperatorsTable from './OperatorsTable';
import { DrawerHeader } from 'common/styles/styledComponents';
import { SiteOperator } from 'pages/settings/types';
import { hasPermission } from 'common/helpers/utils';

const SiteOperators: React.FC = () => {
  const classes = useContentStyles();
  const timerRef = useRef<number>();
  const dispatch = useDispatch();
  const TIMEOUT = 1000;
  const [openForm, setOpenForm] = useState(false);
  const [isUpdate, setUpdate] = useState(true);
  const [isEdit, setEdit] = useState(false);
  const [selectedOperator, setSelectedOperator] = useState<SiteOperator>({
    operatorId: '',
    name: '',
  });
  const [openDialogue, setOpenDialogue] = React.useState(false);

  const [operators, setOperators] = useState<SiteOperator[]>([]);

  const updateTable = useCallback(async () => {
    try {
      const operatorsResponse = await apiClient.get('/site-operators');
      setOperators(operatorsResponse.data.data as SiteOperator[]);
    } catch (error: any) {
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: 'error',
        }),
      );
    }
  }, [dispatch]);

  useEffect(() => {
    if (isUpdate) {
      updateTable();
      setUpdate(false);
    }
    return () => clearTimeout(timerRef.current);
  }, [isUpdate, updateTable]);

  const openAddForm = () => {
    setOpenForm(true);
  };
  const onCloseForm = () => {
    setOpenForm(false);
  };
  const onCloseEdit = () => {
    setEdit(false);
  };

  const onSaveUpdateTable = () => {
    timerRef.current = window.setTimeout(() => {
      setEdit(false);
      setOpenForm(false);
      setUpdate(true);
    }, TIMEOUT);
  };

  const onDeleteOperator = async () => {
    try {
      if (selectedOperator.operatorId) {
        const deleteResponse = await apiClient.delete(
          `/site-operators/${selectedOperator.operatorId}`,
        );
        dispatch(
          setSnackbarToast({
            message: deleteResponse.data.meta.message,
            open: true,
            severity: 'success',
          }),
        );
        onDeleteUpdateTable();
      }
    } catch (error: any) {
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: 'error',
        }),
      );
      setOpenDialogue(false);
    }
  };

  const handleDeleteOperator = (operatorObj: SiteOperator) => {
    setSelectedOperator(operatorObj);
    setOpenDialogue(true);
  };

  const onDeleteUpdateTable = () => {
    setOpenDialogue(false);
    setUpdate(true);
  };

  const onCancelRemove = () => {
    setOpenDialogue(false);
  };

  const onEditOperator = (operatorObj: SiteOperator) => {
    setSelectedOperator(operatorObj);
    setEdit(true);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.contentPadding}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="none" color="inherit">
            Resources
          </Link>
          <Typography variant="body1">{'Site Operators'}</Typography>
        </Breadcrumbs>
      </div>
      <Card elevation={0} className={classes.contentSection}>
        <CardHeader
          disableTypography
          title="Operators"
          className={classes.cardHeader}
          action={
            <Button
              onClick={openAddForm}
              color="info"
              disabled={!hasPermission('operators', 'write')}
              startIcon={<PlaylistAddIcon />}>
              {'Add Site Operator'}
            </Button>
          }></CardHeader>
        <Drawer
          anchor="right"
          open={openForm}
          classes={{ paper: classes.formPaper }}>
          <DrawerHeader />
          <AddOperator
            onSaveUpdateTable={onSaveUpdateTable}
            onClose={onCloseForm}></AddOperator>
        </Drawer>
        <Drawer
          anchor="right"
          open={isEdit}
          classes={{ paper: classes.formPaper }}>
          <DrawerHeader />
          <EditOperator
            onSaveUpdateTable={onSaveUpdateTable}
            onClose={onCloseEdit}
            selectedOperator={selectedOperator}></EditOperator>
        </Drawer>
        {openDialogue && (
          <DeleteOperator
            onDeleteOperator={onDeleteOperator}
            selectedOperator={selectedOperator}
            open={openDialogue}
            onCancelRemove={onCancelRemove}></DeleteOperator>
        )}
        <OperatorsTable
          data={operators}
          handleEditOperator={onEditOperator}
          handleDeleteOperator={handleDeleteOperator}></OperatorsTable>
      </Card>
    </React.Fragment>
  );
};

export default SiteOperators;
