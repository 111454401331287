import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface LoaderState {
  openLoader: boolean;
  loaderMessage: string;
}
interface SnackBarToastState {
  open: boolean;
  message: string;
  timeout?: number;
  severity: 'success' | 'info' | 'warning' | 'error';
}
export interface UiState {
  loader: LoaderState;
  snackBarToast: SnackBarToastState;
}

const initialState: UiState = {
  loader: {
    openLoader: false,
    loaderMessage: '',
  },
  snackBarToast: {
    open: false,
    message: '',
    timeout: 100,
    severity: 'success',
  },
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setLoader: (state, action: PayloadAction<LoaderState>) => {
      state.loader = action.payload;
    },
    setSnackbarToast: (state, action: PayloadAction<SnackBarToastState>) => {
      state.snackBarToast = action.payload;
    },
  },
});

export const { setLoader, setSnackbarToast } = uiSlice.actions;

export default uiSlice.reducer;
