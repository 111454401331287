import React from 'react';
import { Card, CardHeader, CardContent, Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import TcTextField from 'common/components/TcTextField';
import TcSelectWithStringArray from 'common/components/TcSelectWithStringArray';

type OPNConfigForm = {
  name: string;
  portType: string;
  host: string;
  port: string;
  portMode: string;
  portTimeout: string;
  protocol: string;
  protocolTimeout: string;
  badPacketDrop: string;
  bufferSize: string;
};

const formDefaultValues: OPNConfigForm = {
  name: '',
  portType: '',
  host: '',
  port: '',
  protocol: '',
  portMode: '',
  portTimeout: '',
  protocolTimeout: '',
  badPacketDrop: '',
  bufferSize: '',
};

const protocols = ['Modbus', 'TCP', 'UDP', 'SSH', 'RDP', 'HTTP', 'HTTPs'];
const portTypes = ['TCP', 'UDP', 'SSH', 'RDP'];
const portModes = ['Send', 'Pull'];
const badPacketDropValues = ['Enable', 'Disable'];

const OPNConfigTab: React.FC = () => {
  const methods = useForm<OPNConfigForm>({
    defaultValues: formDefaultValues,
    mode: 'onBlur',
  });

  return (
    <Card variant="outlined">
      <CardHeader
        title="Port Specifications"
        disableTypography
        style={{ fontSize: '20px' }}
      />
      <CardContent>
        <FormProvider {...methods}>
          <Grid container spacing={2}>
            <Grid container item xs={5} spacing={2}>
              <Grid item xs={10}>
                <TcTextField name="name" label="Name" />
              </Grid>
              <Grid item xs={10}>
                <TcSelectWithStringArray
                  name="portType"
                  label="Port Type"
                  options={portTypes}
                />
              </Grid>
              <Grid item xs={10}>
                <TcTextField name="host" label="Host" />
              </Grid>
              <Grid item xs={10}>
                <TcTextField name="port" label="Port" />
              </Grid>
              <Grid item xs={10}>
                <TcSelectWithStringArray
                  name="portMode"
                  label="Port Mode"
                  options={portModes}
                />
              </Grid>
              <Grid item xs={10}>
                <TcTextField name="portTimeout" label="Port Timeout (ms)" />
              </Grid>
              <Grid item xs={10}>
                <TcSelectWithStringArray
                  name="protocol"
                  label="Protocol"
                  options={protocols}
                />
              </Grid>
              <Grid item xs={10}>
                <TcTextField
                  name="protocolTimeout"
                  label="Protocol Timeout (ms)"
                />
              </Grid>
              <Grid item xs={10}>
                <TcSelectWithStringArray
                  name="badPacketDrop"
                  label="Bad Packet Drop"
                  options={badPacketDropValues}
                />
              </Grid>
              <Grid item xs={10}>
                <TcTextField name="bufferSize" label="Buffer Size" />
              </Grid>
            </Grid>
          </Grid>
        </FormProvider>
      </CardContent>
    </Card>
  );
};

export default OPNConfigTab;
