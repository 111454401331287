import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, Autocomplete, TextField } from '@mui/material';

interface TcAutocompleteProps<T> {
  name: string;
  label: string;
  defaultValue?: string | number;
  value: T;
  options: T[];
  optionKey: keyof T;
  rules?: Record<string, any>;
  onValueSelect: (
    _event: React.SyntheticEvent<Element, Event>,
    value: T | null,
  ) => void;
  getOptionLabel: (option: T) => string;
}

const TcAutocomplete = <T extends Record<string, any>>({
  name,
  defaultValue = '',
  label,
  options,
  rules,
  value,
  optionKey,
  onValueSelect,
  getOptionLabel,
}: TcAutocompleteProps<T>) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <FormControl fullWidth variant="outlined" error={Boolean(errors[name])}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <Autocomplete
            {...field}
            size="small"
            options={options}
            value={value}
            isOptionEqualToValue={(option) =>
              option[optionKey] === value[optionKey]
            }
            getOptionLabel={(option) => getOptionLabel(option)}
            onChange={onValueSelect}
            renderInput={(params) => (
              <TextField
                {...params}
                data-testid={name}
                size="small"
                label={label}
                variant="outlined"
                error={Boolean(errors[name])}
                helperText={
                  errors[name]
                    ? (errors[name]?.message as unknown as string)
                    : ''
                }
                fullWidth
              />
            )}
          />
        )}
        rules={rules}
      />
    </FormControl>
  );
};

export default TcAutocomplete;
