import React from 'react';
import { Dialog, DialogTitle, DialogActions, Button } from '@mui/material';
import { ConnectionSetting } from '../types';

type DeleteHttpsConnectionProps = {
  selectedSetting: ConnectionSetting;
  open: boolean;
  onCancelRemove: () => void;
  onDelete: () => void;
};

const DeleteHttpsConnection: React.FC<DeleteHttpsConnectionProps> = (props) => {
  const handleRemove = () => {
    props.onDelete();
  };

  return (
    <React.Fragment>
      <Dialog open={props.open}>
        <DialogTitle>{`Are you sure you want to delete settings?`}</DialogTitle>
        <DialogActions>
          <Button
            onClick={props.onCancelRemove}
            color="info"
            variant="outlined">
            {'Cancel'}
          </Button>
          <Button
            onClick={handleRemove}
            color="warning"
            autoFocus
            variant="contained">
            {'Remove'}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default DeleteHttpsConnection;
