import React from 'react';
import {
  Grid,
  Card,
  CssBaseline,
  FormControlLabel,
  Radio,
  RadioGroup,
  CardContent,
  FormLabel,
  CardHeader,
  FormControl,
  Button,
} from '@mui/material';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import apiClient from 'common/apiClientAxios';
import { setThemeMode } from 'redux/ThemeSlice';
import { setLoader, setSnackbarToast } from 'redux/UiStateSlice';
import { PreferencesForm } from '../types';
import { useContentStyles } from 'common/styles/useContentStyles';
import { constants } from 'common/constants';
import { User } from 'pages/users/types';

type PreferencesProps = {
  user: User;
};

const Preferences: React.FC<PreferencesProps> = ({ user }) => {
  const classes = useContentStyles();
  const dispatch = useDispatch();
  const methods = useForm<PreferencesForm>({
    defaultValues: {
      mode: user.mode,
    },
    mode: 'onBlur',
  });
  const {
    control,
    handleSubmit,
    formState: { isValid, isDirty },
  } = methods;

  const handleSaveClick = handleSubmit(async (data: PreferencesForm) => {
    try {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        }),
      );
      const userResponse = await apiClient.put(
        `/users/${user.userId}/preferences`,
        {
          mode: data.mode,
        },
      );
      sessionStorage.getItem('user')
        ? sessionStorage.setItem(
            'user',
            JSON.stringify({ ...user, mode: data.mode }),
          )
        : localStorage.setItem(
            'user',
            JSON.stringify({ ...user, mode: data.mode }),
          );
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        }),
      );
      dispatch(
        setSnackbarToast({
          message: userResponse.data.meta.message,
          open: true,
          severity: 'success',
        }),
      );
      dispatch(setThemeMode(data.mode));
    } catch (error: any) {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        }),
      );
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: 'error',
        }),
      );
    }
  });

  return (
    <React.Fragment>
      <CssBaseline />
      <Card variant="outlined">
        <CardHeader
          title="Preferences"
          disableTypography
          className={classes.cardHeader}></CardHeader>
        <CardContent>
          <FormProvider {...methods}>
            <form noValidate>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item>
                  <FormLabel component="legend">Visual Mode: </FormLabel>
                </Grid>
                <FormControl component="fieldset">
                  <Controller
                    name="mode"
                    control={control}
                    render={({ field }) => (
                      <RadioGroup {...field}>
                        <Grid container direction="row" sx={{ paddingLeft: 1 }}>
                          <Grid item>
                            <FormControlLabel
                              value={'light'}
                              control={<Radio />}
                              label="Light"
                            />
                          </Grid>
                          <Grid item>
                            <FormControlLabel
                              value={'dark'}
                              control={<Radio />}
                              label="Dark"
                            />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    )}
                  />
                </FormControl>
                <Grid item xs={2}>
                  <Button
                    size="small"
                    type="submit"
                    onClick={handleSaveClick}
                    fullWidth
                    variant="contained"
                    color="info"
                    id="save"
                    disabled={!isValid || !isDirty}>
                    {'Save'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default Preferences;
