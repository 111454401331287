import React, { useState } from 'react';
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  List,
  ClickAwayListener,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { RootState } from '../../redux/Store';
import { RouteType } from '../../routes/types';
import { useSelector } from 'react-redux';
import SidebarItemCollapse from './SidebarItemCollapse';
import PopoverSidebarItem from './PopoverSidebarItem';

type Props = {
  item: RouteType;
  isShowPopover?: boolean;
};

const SidebarItem: React.FC<Props> = ({ item, isShowPopover }) => {
  const { appState } = useSelector((state: RootState) => state.appState);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return item.sidebarProps && item.path ? (
    <>
      <ListItemButton
        component={Link}
        to={isShowPopover ? '#' : item.path}
        onClick={handlePopoverOpen}
        sx={{
          '&:hover': {
            backgroundColor: 'secondary.light',
          },
          borderLeft: appState === item.state ? '4px solid' : '0px',
          borderLeftColor: appState === item.state ? 'secondary.main' : 'unset',
          paddingY: 1,
          paddingX: 3,
        }}>
        <ListItemIcon
          sx={{
            paddingX: item.isSubMenu ? 3 : 0,
            color: appState === item.state ? 'secondary.main' : 'unset',
          }}>
          {item.sidebarProps.icon ?? null}
        </ListItemIcon>
        <ListItemText
          primary={item.sidebarProps.displayText}
          sx={{
            color: appState === item.state ? 'secondary.main' : 'unset',
          }}
        />
      </ListItemButton>
      {isShowPopover && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}>
          <ClickAwayListener onClickAway={handlePopoverClose}>
            <List onMouseLeave={handlePopoverClose}>
              {item.child?.map((route, index) => {
                if (!route.sidebarProps) {
                  return null;
                }
                if (route.child) {
                  return (
                    <SidebarItemCollapse
                      item={route}
                      key={`route_${route.state}_${index}`}
                    />
                  );
                }
                return (
                  <PopoverSidebarItem
                    item={route}
                    onClick={handlePopoverClose}
                    key={`route_${route.state}_${index}`}
                  />
                );
              })}
            </List>
          </ClickAwayListener>
        </Popover>
      )}
    </>
  ) : null;
};

export default SidebarItem;
