import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Paper,
} from '@mui/material';

import { DeviceGroup } from '../types';
import { useContentStyles } from 'common/styles/useContentStyles';

type SelectGroupDialogProps = {
  open: boolean;
  groups: DeviceGroup[];
  onCancel: () => void;
  onSaveGroup: (groups?: string[]) => void;
};
const SelectGroupDialog: React.FC<SelectGroupDialogProps> = ({
  open,
  groups,
  onCancel,
  onSaveGroup,
}) => {
  const classes = useContentStyles();
  const handleSubmitGroup = () => {
    const dynamicGroupIds = groups.map((grp) => grp.groupId);
    onSaveGroup(dynamicGroupIds);
  };
  return (
    <React.Fragment>
      <Dialog open={open}>
        <DialogContent>
          <DialogContentText>
            This device will automatically become part of the following list of
            groups.
          </DialogContentText>
          <Paper className={classes.dialogListContainer}>
            <ul className={classes.ulListItem}>
              {groups.map((group) => (
                <li key={group.groupId}>{group.name}</li>
              ))}
            </ul>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="info" variant="outlined">
            {'Cancel'}
          </Button>
          <Button
            onClick={handleSubmitGroup}
            color="info"
            autoFocus
            variant="contained">
            {'Save'}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default SelectGroupDialog;
