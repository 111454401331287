import React from 'react';

import { CssBaseline, Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import apiClient from 'common/apiClientAxios';
import { setLoader, setSnackbarToast } from 'redux/UiStateSlice';
import TcTextField from 'common/components/TcTextField';
import { useDrawerFormStyles } from 'common/styles/useDrawerFormStyles';
import FormHeader from 'common/components/FormHeader';
import { SiteOperator } from 'pages/settings/types';
import { constants } from 'common/constants';

type EditOperatorProps = {
  onClose: () => void;
  onSaveUpdateTable: () => void;
  selectedOperator: SiteOperator;
};

const EditOperator: React.FC<EditOperatorProps> = (props) => {
  const classes = useDrawerFormStyles();
  const dispatch = useDispatch();
  const onClose = () => {
    props.onClose();
  };
  const defaultValues: SiteOperator = {
    name: props.selectedOperator.name,
    description: props.selectedOperator.description ?? '',
    operatorId: props.selectedOperator.operatorId,
  };

  const methods = useForm<SiteOperator>({
    defaultValues,
    mode: 'onBlur',
  });
  const {
    handleSubmit,
    formState: { isValid, isDirty },
  } = methods;
  const onUpdate = handleSubmit(async (data: SiteOperator) => {
    try {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        }),
      );
      const saveResponse = await apiClient.put(
        `/site-operators/${props.selectedOperator.operatorId}`,
        { ...data },
      );
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        }),
      );
      dispatch(
        setSnackbarToast({
          message: saveResponse.data.meta.message,
          open: true,
          severity: 'success',
        }),
      );
    } catch (error: any) {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        }),
      );
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: 'error',
        }),
      );
    }
    props.onSaveUpdateTable();
  });

  return (
    <React.Fragment>
      <CssBaseline />
      <FormHeader
        title={`${props.selectedOperator.name}`}
        onClose={onClose}></FormHeader>
      <FormProvider {...methods}>
        <form noValidate>
          <Grid container spacing={2} className={classes.formContainer}>
            <Grid item xs={12}>
              <TcTextField
                name="name"
                label="Name *"
                rules={{
                  required: 'Name is required',
                  pattern: {
                    value: constants.NAME_REGEX,
                    message: 'Invalid name',
                  },
                }}></TcTextField>
            </Grid>
            <Grid item xs={12}>
              <TcTextField name="description" multiline rows={2}></TcTextField>
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.bottomDivider}>
            <Grid item xs={2}>
              <Button
                size="small"
                type="submit"
                disabled={!isValid || !isDirty}
                onClick={onUpdate}
                fullWidth
                variant="contained"
                color="info">
                {'Save'}
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                size="small"
                fullWidth
                variant="outlined"
                color="info"
                onClick={onClose}>
                {'Cancel'}
              </Button>
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
        </form>
      </FormProvider>
    </React.Fragment>
  );
};

export default EditOperator;
