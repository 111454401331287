import React from 'react';
import { Chip, Grid, Button } from '@mui/material';
import { LogsFilterData, LogsFilterMasterData } from './types';
import dayjs from 'dayjs';

interface LogsFilterChipDisplayProps {
  filters: LogsFilterData;
  logsMasterData: LogsFilterMasterData;
  handleUpdatedFilter: (filters: LogsFilterData) => void;
  openFilter: () => void;
}

const LogsFilterChipDisplay: React.FC<LogsFilterChipDisplayProps> = ({
  filters,
  handleUpdatedFilter,
  openFilter,
  logsMasterData,
}) => {
  const handleFilterOptionDelete = (
    option: string,
    type: keyof LogsFilterData,
  ) => {
    switch (type) {
      case 'eventTypes':
        {
          const updated = filters.eventTypes?.filter((e) => e !== option);
          handleUpdatedFilter({ ...filters, eventTypes: updated });
        }
        break;
      case 'severity':
        {
          const updated = filters.severity?.filter((e) => e !== option);
          handleUpdatedFilter({ ...filters, severity: updated });
        }
        break;
      case 'siteIds':
        {
          const updated = filters.siteIds?.filter((e) => e !== option);
          handleUpdatedFilter({ ...filters, siteIds: updated });
        }
        break;
      case 'userIds':
        {
          const updated = filters.userIds?.filter((e) => e !== option);
          handleUpdatedFilter({ ...filters, userIds: updated });
        }
        break;
      case 'userIp':
        handleUpdatedFilter({ ...filters, userIp: '' });
        break;
      case 'startDate':
        handleUpdatedFilter({ ...filters, startDate: undefined });
        break;
      case 'endDate':
        handleUpdatedFilter({ ...filters, endDate: undefined });
        break;
      default:
        break;
    }
  };

  const renderChip = (
    type: keyof LogsFilterData,
    label: string,
    optionId: string,
  ) => (
    <Grid item key={`${type}${optionId}`}>
      <Chip
        variant="outlined"
        label={label}
        onDelete={() => handleFilterOptionDelete(optionId, type)}
      />
    </Grid>
  );

  const chips = [
    filters.userIp && renderChip('userIp', filters.userIp, 'userIp'),
    ...filters.severity?.map((value) => {
      const option = logsMasterData.severity.find((opt) => opt.value === value);
      return renderChip('severity', option?.value || '', option?.value || '');
    }),
    ...filters.eventTypes?.map((value) => {
      const option = logsMasterData.eventTypes.find(
        (opt) => opt.value === value,
      );
      return renderChip('eventTypes', option?.value || '', option?.value || '');
    }),
    ...filters.userIds?.map((value) => {
      const option = logsMasterData.users.find((opt) => opt.userId === value);
      return renderChip(
        'userIds',
        `${option?.firstName} ${option?.lastName}` || '',
        option?.userId || '',
      );
    }),
    ...filters.siteIds?.map((value) => {
      const option = logsMasterData.sites.find((opt) => opt.siteId === value);
      return renderChip('siteIds', option?.name || '', option?.siteId || '');
    }),
    filters.startDate &&
      renderChip(
        'startDate',
        `Start Date: ${dayjs(filters.startDate).format('MM/DD/YYYY')}`,
        'startDate',
      ),
    filters.endDate &&
      renderChip(
        'endDate',
        `End Date: ${dayjs(filters.endDate).format('MM/DD/YYYY')}`,
        'endDate',
      ),
  ].filter(Boolean);

  return (
    <Grid container spacing={1} paddingBottom={1} paddingLeft={'2px'}>
      {chips.slice(0, 7).map((chip, index) => (
        <Grid item key={index}>
          {chip}
        </Grid>
      ))}
      {chips.length > 7 && (
        <>
          <Grid item>...</Grid>
          <Grid item>
            <Button onClick={openFilter} color="info">
              {'Show All'}
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default LogsFilterChipDisplay;
