import React from 'react';
import {
  CssBaseline,
  Button,
  Typography,
  IconButton,
  Box,
  Card,
  CardContent,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { useDrawerFormStyles } from 'common/styles/useDrawerFormStyles';
import { LogEvent } from './types';

type LogDetailsProps = {
  onClose: () => void;
  logEvent: LogEvent;
};
dayjs.extend(utc);
const LogDetails: React.FC<LogDetailsProps> = ({ onClose, logEvent }) => {
  const classes = useDrawerFormStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <Grid container className={classes.formTitleContainer}>
        <Grid item xs={11}>
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <Typography component="h6" variant="h6" gutterBottom>
              {'Log Event Information'}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={1}>
          <IconButton
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              color: (theme) => theme.palette.grey[700],
            }}>
            <CloseOutlinedIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container spacing={1} sx={{ padding: 2 }}>
        <Grid item>
          <strong>Event Time:</strong>{' '}
          {dayjs(new Date(logEvent.createdOn))
            .utc()
            .format('MM/DD/YYYY, hh:mm A')}
        </Grid>
        <Grid item>
          <strong>Log Event:</strong> {logEvent.logEventType}
        </Grid>
        <Grid item>
          <strong>User:</strong> {logEvent.username ?? logEvent.email ?? '-'}
        </Grid>
        <Grid item>
          <strong>Site:</strong> {logEvent.siteName ?? '-'}
        </Grid>
        <Grid item>
          <strong>Device:</strong> {logEvent.deviceName ?? '-'}
        </Grid>
        <Grid item xs={12}>
          <Card variant="outlined" sx={{ padding: 2 }}>
            <CardContent
              sx={{
                whiteSpace: 'pre-wrap',
                wordWrap: 'break-word',
                overflowWrap: 'break-word',
              }}>
              {logEvent.userIp && (
                <Typography variant="body2" color="textSecondary">
                  User Ip: {logEvent.userIp}
                </Typography>
              )}
              {logEvent.sessionId && (
                <Typography variant="body2" color="textSecondary">
                  Session Id: {logEvent.sessionId}
                </Typography>
              )}
              <Typography variant="body2" color="textSecondary">
                {logEvent.information
                  ? logEvent.information
                  : 'No Additional Information'}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={2} justifyContent="flex-end">
        <Grid item xs={1} sx={{ marginRight: 2 }}>
          <Button
            size="small"
            fullWidth
            variant="outlined"
            color="info"
            onClick={onClose}>
            {'Close'}
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default LogDetails;
