import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import { getTimezone } from 'common/helpers/utils';

interface AdminAccessCountdownProps {
  deviceTimezone?: string;
}

const AdminAccessCountdown: React.FC<AdminAccessCountdownProps> = ({
  deviceTimezone,
}) => {
  const [deviceLocalTime, setDeviceLocalTime] = useState('');
  useEffect(() => {
    const updateDeviceTime = () => {
      setDeviceLocalTime(
        dayjs()
          .tz(deviceTimezone ?? getTimezone())
          .format('hh.mmA z'),
      );
    };
    updateDeviceTime();
    const intervalId = setInterval(updateDeviceTime, 1000);

    return () => clearInterval(intervalId); // Clear the interval on unmount
  }, [deviceTimezone]);

  return (
    <Typography component="span" sx={{ fontSize: 18 }}>
      {`Local Time at the Device is: `}
      <Typography component="span" sx={{ fontWeight: 'bold' }}>
        {deviceLocalTime}
      </Typography>
    </Typography>
  );
};

export default AdminAccessCountdown;
