import { createTheme, alpha } from '@mui/material/styles';
import { PaletteOptions } from '@mui/material/styles/createPalette';
import { TypographyOptions } from '@mui/material/styles/createTypography';

export interface ICustomThemeOptions {
  primaryColor: string;
  secondaryColor: string;
  font: string;
  successColor: string;
  infoColor: string;
  warningColor: string;
  errorColor: string;
  mode: 'light' | 'dark';
}

export const paletteColors = {
  backgroundDefaultLight: '#f4f4f4',
  backgroundDefaultDark: '#1e1e1e', //#32353a
  textDisabledDark: '#757575',
  textDisabledLight: '#9e9e9e',
  textPrimaryLight: '#212121',
  textPrimaryDark: '#ffffff',
  textSecondaryLight: '#757575',
  textSecondaryDark: '#B0BEC5',
  textHintLight: '#00000061',
  textHintDark: '#b0bec5',
  paperLight: '#ffffff',
  paperDark: '#121212',
  activeActionLight: '#5f6368',
  activeActionDark: '#80cbc4',
  disabledActionLight: '#e0e0e0',
  disabledActionDark: '#3a3a3a',
};

const customTheme = (themeOptions: ICustomThemeOptions) => {
  const isDarkMode = themeOptions.mode === 'dark';

  // Additional overrides for Mui components
  const components = {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputLabel-root.Mui-focused': {
            color: isDarkMode
              ? paletteColors.textHintDark
              : paletteColors.textHintLight,
          },
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: isDarkMode
                ? paletteColors.textHintDark
                : paletteColors.textHintLight,
            },
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: themeOptions.infoColor,
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: themeOptions.infoColor,
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: isDarkMode
              ? themeOptions.infoColor
              : paletteColors.textPrimaryLight,
          },
        },
      },
    },
  };

  const palette: PaletteOptions = {
    mode: themeOptions.mode,
    primary: {
      main: themeOptions.primaryColor,
      contrastText: isDarkMode
        ? paletteColors.paperDark
        : paletteColors.paperLight,
      light: alpha(themeOptions.primaryColor, 0.2),
    },
    secondary: {
      main: themeOptions.secondaryColor,
      contrastText: isDarkMode
        ? paletteColors.paperDark
        : paletteColors.paperLight,
      light: alpha(themeOptions.secondaryColor, 0.5),
    },
    success: {
      main: themeOptions.successColor,
    },
    info: {
      main: themeOptions.infoColor,
    },
    warning: {
      main: themeOptions.warningColor,
    },
    error: {
      main: themeOptions.errorColor,
    },
    action: {
      hover: alpha(themeOptions.infoColor, 0.08),
      selected: alpha(themeOptions.infoColor, 0.3),
      active: isDarkMode
        ? paletteColors.activeActionDark
        : paletteColors.activeActionLight,
      disabledBackground: isDarkMode
        ? paletteColors.disabledActionDark
        : paletteColors.disabledActionLight,
      disabledOpacity: 0.38,
    },
    background: {
      paper: isDarkMode ? paletteColors.paperDark : paletteColors.paperLight,
      default: isDarkMode
        ? paletteColors.backgroundDefaultDark
        : paletteColors.backgroundDefaultLight,
    },
    text: {
      primary: isDarkMode
        ? paletteColors.textPrimaryDark
        : paletteColors.textPrimaryLight,
      secondary: isDarkMode
        ? paletteColors.textSecondaryDark
        : paletteColors.textSecondaryLight,
      disabled: isDarkMode
        ? paletteColors.textDisabledDark
        : paletteColors.textDisabledLight,
    },
  };

  const typography: TypographyOptions = {
    fontFamily: themeOptions.font,
    body1: {
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '0.1px',
      textAlign: 'left',
    },
    body2: {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px',
      letterSpacing: '0.15000000596046448px',
      textAlign: 'left',
    },
    subtitle1: {
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '28px',
      letterSpacing: '0.15000000596046448px',
      textAlign: 'left',
    },
    subtitle2: {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '22px',
      letterSpacing: '0.10000000149011612px',
      textAlign: 'left',
    },
    caption: {
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '19.92px',
      letterSpacing: '0.4px',
      textAlign: 'left',
      color: isDarkMode ? '#cfd8dc' : 'rgba(0, 0, 17, 0.87)',
    },
    overline: {
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '32px',
      letterSpacing: '1px',
      textAlign: 'left',
      textTransform: 'uppercase',
    },
    button: {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '0.46000000834465027px',
      textAlign: 'left',
      textTransform: 'none',
    },
    h1: {
      fontSize: '96px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '112px',
      letterSpacing: '-1.5px',
      textAlign: 'left',
    },
    h2: {
      fontSize: '60px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '72px',
      letterSpacing: '-0.5000000596046448px',
      textAlign: 'left',
    },
    h3: {
      fontSize: '48px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '56px',
      letterSpacing: '0',
      textAlign: 'left',
    },
    h4: {
      fontSize: '34px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '42px',
      letterSpacing: '0.25px',
      textAlign: 'left',
    },
    h5: {
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '32px',
      letterSpacing: '0px',
      textAlign: 'left',
    },
    h6: {
      fontSize: '20px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '32px',
      letterSpacing: '0.15000000596046448px',
      textAlign: 'left',
    },
  };

  return createTheme({
    palette,
    typography,
    components,
  });
};

export default customTheme;
