import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { LogEvent } from './types';

dayjs.extend(utc);
type LogsChartBarProps = {
  chartsData: LogEvent[];
  themeMode: 'light' | 'dark';
};

const LogEventsBarChart: React.FC<LogsChartBarProps> = ({
  chartsData,
  themeMode,
}) => {
  const chartRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const textColor = themeMode === 'light' ? '#000000' : '#B0BEC5';
    const parsedData = chartsData.map((event) => ({
      createdOn: new Date(event.createdOn),
    }));

    const groupedData = d3.rollups(
      parsedData,
      (v) => v.length,
      (d) => d3.utcHour.floor(d.createdOn),
    );

    const formattedData = groupedData.map(([time, count]) => ({
      time: time as Date,
      count: count as number,
    }));

    // Set dimensions
    const margin = { top: 30, right: 20, bottom: 50, left: 50 };
    const width = 1400 - margin.left - margin.right;
    const height = 400 - margin.top - margin.bottom;

    // Set scales
    const xScale = d3
      .scaleBand()
      .domain(formattedData.map((d) => d.time.toISOString()))
      .range([0, width])
      .padding(0.2);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(formattedData, (d) => d.count) || 0])
      .range([height, 0])
      .nice();
    yScale.ticks();

    // Clear previous SVG
    d3.select(chartRef.current).select('svg').remove();

    // Create SVG
    const svg = d3
      .select(chartRef.current)
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);

    // Draw X-axis
    const xAxis = d3
      .axisBottom(xScale)
      .tickFormat((d) => d3.utcFormat('%I:%M%p')(new Date(d)));
    const yAxis = d3.axisLeft(yScale).ticks(5).tickSize(0);
    svg
      .append('g')
      .attr('transform', `translate(0, ${height})`)
      .call(xAxis)
      .selectAll('text')
      .attr('transform', 'rotate(0)')
      .style('text-anchor', 'middle');

    // Draw Y-axis
    svg.append('g').call(yAxis).select('.domain').remove();

    // Add horizontal grid lines (dotted)
    svg
      .append('g')
      .selectAll('line.horizontal-grid')
      .data(yScale.ticks(5))
      .enter()
      .append('line')
      .attr('class', 'horizontal-grid')
      .attr('x1', 0)
      .attr('x2', width)
      .attr('y1', (d) => yScale(d))
      .attr('y2', (d) => yScale(d))
      .attr('stroke', '#ccc')
      .attr('stroke-width', 1)
      .attr('stroke-dasharray', '4');

    // Draw Bars
    const bars = svg
      .selectAll('.bar')
      .data(formattedData)
      .enter()
      .append('rect')
      .attr('class', 'bar')
      .attr('x', (d) => xScale(d.time.toISOString())!)
      .attr('y', (d) => yScale(d.count))
      .attr('width', width / formattedData.length - 2)
      .attr('height', (d) => height - yScale(d.count))
      .attr('fill', '#084f9b');

    // Add hover tooltip for bars
    const tooltip = d3
      .select(chartRef.current)
      .append('div')
      .style('position', 'absolute')
      .style('background', 'white')
      .style('border', '1px solid #ccc')
      .style('border-radius', '4px')
      .style('padding', '8px')
      .style('font-size', '12px')
      .style('display', 'none')
      .style('pointer-events', 'none');

    bars
      .on('mouseover', (event, d) => {
        const formattedTime = dayjs(d.time).utc().format('hh:mm A');
        tooltip
          .style('display', 'block')
          .style('color', '#000')
          .html(`${formattedTime}, Count: ${d.count}`)
          .style('left', `${event.pageX + 10}px`)
          .style('top', `${event.pageY - 20}px`);
      })
      .on('mouseout', () => {
        tooltip.style('display', 'none');
      });

    // Add a horizontal baseline slightly above the X-axis
    svg
      .append('line')
      .attr('x1', 0)
      .attr('x2', width)
      .attr('y1', height - 5)
      .attr('y2', height - 5)
      .attr('stroke', 'aqua')
      .attr('stroke-width', 2);

    // Add chart title
    svg
      .append('text')
      .attr('x', width / 2)
      .attr('y', -10)
      .attr('text-anchor', 'middle')
      .text('Log Events')
      .style('font-size', '16px')
      .style('font-weight', 'bold')
      .style('fill', textColor);

    // Add Y-axis label
    svg
      .append('text')
      .attr('transform', 'rotate(-90)')
      .attr('x', -height / 2)
      .attr('y', -40)
      .text('Event Count')
      .style('font-size', '12px')
      .style('fill', textColor);

    // Add legend for baseline
    svg
      .append('circle')
      .attr('cx', width - 85)
      .attr('cy', 15)
      .attr('r', 10)
      .attr('fill', 'aqua');

    svg
      .append('text')
      .attr('x', width - 70)
      .attr('y', 20)
      .text('Baseline')
      .style('font-size', '12px')
      .style('fill', textColor);
  }, [chartsData, themeMode]);

  return <div ref={chartRef}></div>;
};

export default LogEventsBarChart;
