import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CssBaseline,
  Grid,
  Typography,
} from '@mui/material';

import { Country, State, SiteFormMasterData, SiteFilterData } from '../types';
import { useFilterFormStyles } from 'common/styles/useFilterFormStyles';
import TcCheckboxInput from 'common/components/TcCheckboxInput';
import TcTextField from 'common/components/TcTextField';
import '../../../common/styles/filter.css';
import TcAutocompleteMultiSelect from 'common/components/TcAutocompleteMultiSelect';

type FilterProps = {
  onApplyFilter: (formData: any) => void;
  handleClearFilter: () => void;
  handleCancelFilter: () => void;
  filters: SiteFilterData;
  siteFormMasterData: SiteFormMasterData;
};

const SitesFilter: React.FC<FilterProps> = (filterProps) => {
  const classes = useFilterFormStyles();
  const { siteOwners, siteOperators, countries, states } =
    filterProps.siteFormMasterData;

  const [selectedCountries, setSelectedCountries] = useState<Country[]>(
    filterProps.filters.selectedCountries,
  );
  const [selectedStates, setSelectedStates] = useState<State[]>(
    filterProps.filters.selectedStates,
  );

  const methods = useForm({
    defaultValues: filterProps.filters,
  });

  const { handleSubmit, setValue, reset } = methods;

  const onSubmit = handleSubmit(async (data: any) => {
    filterProps.onApplyFilter(data);
  });

  const onCountrySelect = (
    _event: React.SyntheticEvent<Element, Event>,
    value: Country[],
  ) => {
    setValue('selectedCountries', value);
    setSelectedCountries(value);
  };

  const onStateSelect = (
    _event: React.SyntheticEvent<Element, Event>,
    value: State[],
  ) => {
    setValue('selectedStates', value);
    setSelectedStates(value);
  };

  const resetForm = () => {
    reset({
      selectedCountries: [],
      selectedStates: [],
      city: '',
      selectedOwners: [],
      selectedOperators: [],
    });
    setSelectedCountries([]);
    setSelectedStates([]);
  };

  const onClear = () => {
    resetForm();
    filterProps.handleClearFilter();
  };

  const onCancel = () => {
    resetForm();
    filterProps.handleCancelFilter();
  };

  React.useEffect(() => {
    reset(filterProps.filters);
    setSelectedCountries(filterProps.filters.selectedCountries);
    setSelectedStates(filterProps.filters.selectedStates);
  }, [filterProps.filters, reset]);

  return (
    <React.Fragment>
      <CssBaseline />
      <Typography variant="subtitle1">
        <strong>Filters</strong>
      </Typography>
      <FormProvider {...methods}>
        <div className={classes.root}>
          <Card key={2} className={classes.addressCard} variant="outlined">
            <CardHeader
              subheader={
                <Typography variant="subtitle1">
                  <strong>Owner</strong>
                </Typography>
              }
              className={classes.cardHeader}></CardHeader>
            <CardContent className={classes.cardContent}>
              <TcCheckboxInput
                name="selectedOwners"
                optionKey="ownerId"
                optionLabel="name"
                options={siteOwners}
                className="custom-checkbox-font-size"
              />
            </CardContent>
          </Card>
          <Card key={3} className={classes.addressCard} variant="outlined">
            <CardHeader
              subheader={
                <Typography variant="subtitle1">
                  <strong>Operator</strong>
                </Typography>
              }
              className={classes.cardHeader}></CardHeader>
            <CardContent>
              <TcCheckboxInput
                name="selectedOperators"
                optionKey="operatorId"
                optionLabel="name"
                options={siteOperators}
                className="custom-checkbox-font-size"
              />
            </CardContent>
          </Card>
          <Card key={6} className={classes.addressCard} variant="outlined">
            <CardHeader
              subheader={
                <Typography variant="subtitle1">
                  <strong>Address</strong>
                </Typography>
              }
              className={classes.cardHeader}></CardHeader>
            <CardContent>
              <TcAutocompleteMultiSelect
                name="selectedCountries"
                value={
                  selectedCountries.map((country) =>
                    countries.find((c) => c.countryId === country.countryId),
                  ) as Country[]
                }
                label="Country"
                onValueSelect={onCountrySelect}
                optionKey="countryId"
                getOptionLabel={(country) => country.name}
                options={countries}></TcAutocompleteMultiSelect>
            </CardContent>
            <CardContent>
              <TcAutocompleteMultiSelect
                name="selectedStates"
                value={
                  selectedStates.map((state) =>
                    states.find((c) => c.stateId === state.stateId),
                  ) as State[]
                }
                label="State"
                onValueSelect={onStateSelect}
                optionKey="stateId"
                getOptionLabel={(state) => state.name}
                options={states}></TcAutocompleteMultiSelect>
            </CardContent>
            <CardContent>
              <TcTextField name="city"></TcTextField>
            </CardContent>
          </Card>
        </div>
      </FormProvider>
      <Grid container spacing={2} className={classes.bottomDivider}>
        <Grid item xs={2}>
          <Button
            size="small"
            type="submit"
            onClick={onSubmit}
            fullWidth
            variant="contained"
            color="info">
            {'Apply All'}
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Button
            size="small"
            fullWidth
            variant="outlined"
            color="info"
            onClick={onClear}>
            {'Clear All'}
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Button
            size="small"
            fullWidth
            variant="outlined"
            color="info"
            onClick={onCancel}>
            {'Cancel'}
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default SitesFilter;
