import React from 'react';
import { Chip, Grid, Button } from '@mui/material';
import { UserFormMasterData, UsersFilterData } from './types';

interface UsersFilterChipDisplayProps {
  filters: UsersFilterData;
  userFormMasterData: UserFormMasterData;
  handleUpdatedFilter: (filters: UsersFilterData) => void;
  openFilter: () => void;
}

const UsersFilterChipDisplay: React.FC<UsersFilterChipDisplayProps> = ({
  filters,
  handleUpdatedFilter,
  userFormMasterData,
  openFilter,
}) => {
  const handleFilterOptionDelete = (
    option: string,
    filtersData: string[],
    type: keyof UsersFilterData,
  ) => {
    switch (type) {
      case 'groups':
      case 'roles':
      case 'organizations':
      case 'statuses':
      case 'selectedCountries':
      case 'selectedStates':
        handleUpdatedFilter({
          ...filters,
          [type]: filtersData.filter((filterOption) => filterOption !== option),
        });
        break;
      case 'city':
        handleUpdatedFilter({ ...filters, city: '' });
        break;
      default:
        break;
    }
  };

  const renderChip = (
    type: keyof UsersFilterData,
    label: string,
    optionId: string,
    filtersData: string[] = [],
  ) => (
    <Grid item key={`${type}${optionId}`}>
      <Chip
        variant="outlined"
        label={label}
        onDelete={() => handleFilterOptionDelete(optionId, filtersData, type)}
      />
    </Grid>
  );

  const chips = [
    ...filters.groups.map((value) => {
      const option = userFormMasterData.groups.find(
        (opt) => opt.groupId === value,
      );
      return renderChip(
        'groups',
        option?.name || '',
        option?.groupId || '',
        filters.groups,
      );
    }),
    ...filters.roles.map((value) => {
      const option = userFormMasterData.roles.find(
        (opt) => opt.roleId === value,
      );
      return renderChip(
        'roles',
        option?.name || '',
        option?.roleId || '',
        filters.roles,
      );
    }),
    ...filters.selectedCountries.map((value) => {
      const option = userFormMasterData.countries.find(
        (opt) => opt.countryId === value,
      );
      return renderChip(
        'selectedCountries',
        option?.name || '',
        option?.countryId || '',
        filters.selectedCountries,
      );
    }),
    ...filters.selectedStates.map((value) => {
      const option = userFormMasterData.states.find(
        (opt) => opt.stateId === value,
      );
      return renderChip(
        'selectedStates',
        option?.name || '',
        option?.stateId || '',
        filters.selectedStates,
      );
    }),
    filters.city && renderChip('city', filters.city, 'city'),
    ...filters.organizations.map((value) => {
      const option = userFormMasterData.organizations.find(
        (opt) => opt.organizationId === value,
      );
      return renderChip(
        'organizations',
        option?.name || '',
        option?.organizationId || '',
        filters.organizations,
      );
    }),
    ...filters.statuses.map((value) => {
      return renderChip('statuses', value || '', value || '', filters.statuses);
    }),
  ].filter(Boolean);

  return (
    <Grid container spacing={1} paddingBottom={1} paddingLeft={'2px'}>
      {chips.slice(0, 7).map((chip, index) => (
        <Grid item key={index}>
          {chip}
        </Grid>
      ))}
      {chips.length > 7 && (
        <>
          <Grid item>...</Grid>
          <Grid item>
            <Button onClick={openFilter} color="info">
              {'Show All'}
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default UsersFilterChipDisplay;
