import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  CssBaseline,
  Button,
  Breadcrumbs,
  Typography,
  Card,
  CardContent,
  CardActionArea,
  IconButton,
  useTheme,
  Box,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import apiClient from 'common/apiClientAxios';
import { setLoader, setSnackbarToast } from 'redux/UiStateSlice';
import { FormMasterData, RuleForm } from '../types';
import RuleScheduleForm from './RuleScheduleForm';
import TcSelectWithStringArray from 'common/components/TcSelectWithStringArray';
import { constants } from 'common/constants';
import TcTextField from 'common/components/TcTextField';
import { UserGroup } from 'pages/users/types';
import { DeviceGroup } from 'pages/devices/types';
import TcAutocomplete from 'common/components/TcAutocomplete';
import { useContentStyles } from 'common/styles/useContentStyles';
import {
  getAccessWindowDisplaySetting,
  getDateRangeAccessWindow,
  getDateWithTime,
  getDayInfo,
  getRepeatingAccessWindow,
  getTimezone,
} from 'common/helpers/utils';
import { AccessWindowType, RecurrenceUnit, RuleTemplate } from 'common/enums';
import TcTooltip from 'common/components/TcTooltip';
import { RootState } from 'redux/Store';

export const defaultValues: RuleForm = {
  name: '',
  description: '',
  accessWindowType: AccessWindowType.DATE_RANGE,
  startDate: null,
  endDate: null,
  startTime: null,
  endTime: null,
  daysTemplate: RuleTemplate.ALL_DAYS,
  schedule: {
    Sunday: { startTime: null, endTime: null, isActive: false },
    Monday: { startTime: null, endTime: null, isActive: false },
    Tuesday: { startTime: null, endTime: null, isActive: false },
    Wednesday: { startTime: null, endTime: null, isActive: false },
    Thursday: { startTime: null, endTime: null, isActive: false },
    Friday: { startTime: null, endTime: null, isActive: false },
    Saturday: { startTime: null, endTime: null, isActive: false },
  },
  deviceGroupId: '',
  deviceGroupName: '',
  userGroupId: '',
  userGroupName: '',
  zone: {
    zoneId: '',
    name: '',
    createdByName: '',
    expiresOn: '',
    status: '',
  },
  accessProfile: '',
  recurrenceTimeSelectionType: RuleTemplate.CUSTOM,
  recurrenceStartDate: null,
  recurrenceEndDate: null,
  recurrenceStartTime: null,
  recurrenceEndTime: null,
};

export const splitByBreakTag = (htmlString?: string) => {
  return htmlString
    ? htmlString.split('<br/>').map((part, index) => (
        <span key={index}>
          {part}
          <br />
        </span>
      ))
    : '-';
};

export const getStartWindowDate = (htmlString: string) => {
  return htmlString?.split(' to ')[0];
};

const AddRule: React.FC = () => {
  const classes = useContentStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userData } = useSelector((state: RootState) => state.userState);
  const accessWindowDisplay = getAccessWindowDisplaySetting(
    userData.accessWindowDisplay,
  );
  const [formMasterData, setFormMasterData] = useState<FormMasterData>({
    userGroups: [],
    deviceGroups: [],
    rules: [],
  });
  const isRunEffect = useRef(true);

  const getFormMasterData = useCallback(async () => {
    try {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        }),
      );
      const formMasterResponse = await apiClient.get(
        `trust-rules/form/look-up-data`,
      );
      const formMasters = formMasterResponse.data.data as FormMasterData;
      setFormMasterData(formMasters);
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        }),
      );
    } catch (error: any) {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        }),
      );
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: 'error',
        }),
      );
    }
  }, [dispatch]);

  React.useEffect(() => {
    if (isRunEffect.current) {
      getFormMasterData();
      isRunEffect.current = false;
    }
  }, [getFormMasterData, isRunEffect]);
  const onClose = () => {
    navigate(`/portal/trust/list/`);
  };
  const [selectedUserGroup, setSelectedUserGroup] = useState<UserGroup>({
    groupId: '',
    name: '',
  });
  const [selectedDeviceGroup, setSelectedDeviceGroup] = useState<DeviceGroup>({
    groupId: '',
    name: '',
  });
  const [openCalendarModal, setOpenCalendarModal] = React.useState(false);
  const [accessWindow, setAccessWindow] = React.useState('');

  const methods = useForm<RuleForm>({
    defaultValues,
    mode: 'all',
  });

  const {
    handleSubmit,
    getValues,
    setValue,
    formState: { isValid, errors },
  } = methods;

  const onCancelCalendarModal = () => {
    if (getValues('accessWindowType') === AccessWindowType.DATE_RANGE) {
      setValue('startDate', null);
      setValue('endDate', null);
      setValue('startTime', null);
      setValue('endTime', null);
      setValue('daysTemplate', RuleTemplate.ALL_DAYS as string);
    } else {
      setValue('recurrenceStartDate', null);
      setValue('recurrenceEndDate', null);
      setValue('recurrenceStartTime', null);
      setValue('recurrenceEndTime', null);
    }
    setOpenCalendarModal(false);
    setAccessWindow('');
  };

  const onAcceptCalendar = () => {
    setOpenCalendarModal(false);
    if (getValues('accessWindowType') === AccessWindowType.DATE_RANGE) {
      setAccessWindow(
        getDateRangeAccessWindow({
          startDate: getValues('startDate'),
          endDate: getValues('endDate'),
          fromTime: getValues('startTime'),
          toTime: getValues('endTime'),
          daysTemplate: getValues('daysTemplate') as RuleTemplate,
          schedule: getValues('schedule'),
          userZone: accessWindowDisplay.zone,
          timeSelectionType: getValues('timeSelectionType') as RuleTemplate,
        }),
      );
    } else {
      setAccessWindow(
        getRepeatingAccessWindow({
          startDate: getValues('recurrenceStartDate'),
          endDate: getValues('recurrenceEndDate'),
          fromTime: getValues('recurrenceStartTime'),
          toTime: getValues('recurrenceEndTime'),
          schedule: getValues('recurrenceWeekSchedule'),
          recurrence: getValues('recurrence') ?? '1',
          recurrenceUnit: getValues('recurrenceUnit'),
          recurrenceMonth: getValues('recurrenceMonth'),
          userZone: accessWindowDisplay.zone,
        }),
      );
    }
  };
  const onUpdate = handleSubmit(async (data: RuleForm) => {
    let recurrenceMonth = {};
    if (
      data.recurrenceMonth?.option &&
      data.recurrenceUnit === RecurrenceUnit.MONTHS
    ) {
      const dateInfo = getDayInfo(data.recurrenceStartDate);
      recurrenceMonth = {
        dayOfMonth: dateInfo.dayOfMonth,
        dayName: dateInfo.dayName,
        positionInMonth: dateInfo.positionInMonth,
        option: data.recurrenceMonth.option,
        weekNumber: dateInfo.weekNumber,
      };
    }
    try {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        }),
      );
      const startDateWithTime = getDateWithTime(data.startDate, data.startTime);
      const endDateWithTime = getDateWithTime(data.endDate, data.endTime);
      const recurrenceStartDateWithTime = getDateWithTime(
        data.recurrenceStartDate,
        data.recurrenceStartTime,
      );
      const recurrenceEndDateWithTime = getDateWithTime(
        data.recurrenceEndDate,
        data.recurrenceEndTime,
      );
      if (data.schedule) {
        const { schedule } = data;
        constants.DAYS.forEach((day) => {
          if (schedule[day].startTime) {
            schedule[day].startTime = getDateWithTime(
              data.startDate,
              schedule[day].startTime,
            );
          }
          if (schedule[day].endTime) {
            schedule[day].endTime = getDateWithTime(
              data.endDate,
              schedule[day].endTime,
            );
          }
        });
      }
      const trustRuleResponse = await apiClient.post(`/trust-rules`, {
        ...data,
        startDate: startDateWithTime,
        endDate: endDateWithTime,
        startTime: data.startTime ? startDateWithTime : null,
        endTime: data.endTime ? endDateWithTime : null,
        recurrenceStartDate: recurrenceStartDateWithTime,
        recurrenceEndDate: recurrenceEndDateWithTime,
        recurrenceStartTime: data.recurrenceStartTime
          ? recurrenceStartDateWithTime
          : null,
        recurrenceEndTime: data.recurrenceEndTime
          ? recurrenceEndDateWithTime
          : null,
        userGroupName: selectedUserGroup?.name,
        deviceGroupName: selectedDeviceGroup?.name,
        zoneId: data.zone?.zoneId,
        zoneName: data.zone?.name,
        accessWindow,
        recurrenceMonth: { ...recurrenceMonth },
        timezone: getTimezone(),
      });
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        }),
      );
      dispatch(
        setSnackbarToast({
          message: trustRuleResponse.data.meta.message,
          open: true,
          severity: 'success',
        }),
      );
    } catch (error: any) {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        }),
      );
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: 'error',
        }),
      );
    }
    navigate(`/portal/trust/list/`);
  });

  const onSelectUserGroup = (
    _event: React.SyntheticEvent<Element, Event>,
    value: UserGroup | null,
  ) => {
    if (value) {
      setValue('userGroupId', value.groupId);
      setSelectedUserGroup(value);
    }
  };

  const onSelectDeviceGroup = (
    _event: React.SyntheticEvent<Element, Event>,
    value: DeviceGroup | null,
  ) => {
    if (value) {
      setValue('deviceGroupId', value.groupId);
      setSelectedDeviceGroup(value);
    }
  };

  const isNameExists = (name: string) => {
    return formMasterData.rules.some(
      (rule) => rule.name.toLowerCase() === name.toLowerCase(),
    );
  };

  const theme = useTheme();

  useEffect(() => {
    if (getValues('accessWindowType') === AccessWindowType.DATE_RANGE) {
      setAccessWindow(
        getDateRangeAccessWindow({
          startDate: getValues('startDate'),
          endDate: getValues('endDate'),
          fromTime: getValues('startTime'),
          toTime: getValues('endTime'),
          daysTemplate: getValues('daysTemplate') as RuleTemplate,
          schedule: getValues('schedule'),
          userZone: accessWindowDisplay.zone,
          timeSelectionType: getValues('timeSelectionType') as RuleTemplate,
        }),
      );
    } else {
      setAccessWindow(
        getRepeatingAccessWindow({
          startDate: getValues('recurrenceStartDate'),
          endDate: getValues('recurrenceEndDate'),
          fromTime: getValues('recurrenceStartTime'),
          toTime: getValues('recurrenceEndTime'),
          schedule: getValues('recurrenceWeekSchedule'),
          recurrence: getValues('recurrence') ?? '1',
          recurrenceUnit: getValues('recurrenceUnit'),
          recurrenceMonth: getValues('recurrenceMonth'),
          userZone: accessWindowDisplay.zone,
        }),
      );
    }
  }, [accessWindowDisplay.zone, getValues]);

  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.contentPadding}>
        <Breadcrumbs aria-label="breadcrumb">
          <RouterLink
            to="/portal/trust/list"
            style={{
              color: theme.palette.text.secondary,
            }}>
            Trust Rules
          </RouterLink>
          <Typography variant="body1">New Rule</Typography>
        </Breadcrumbs>
      </div>
      <Card
        elevation={0}
        sx={{
          padding: 2,
        }}>
        <FormProvider {...methods}>
          <form noValidate>
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <Typography component="h6" variant="h6" gutterBottom>
                  New Trust Rule
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Box
                  sx={{
                    float: 'right',
                    display: 'flex',
                    alignItems: 'center',
                    color: (theme) => theme.palette.info.main,
                  }}>
                  <Typography>
                    Access window displayed in:{' '}
                    <strong>{accessWindowDisplay.display}</strong>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={10}>
                    <Grid container spacing={2}>
                      <Grid item alignSelf={'center'}>
                        <Typography variant="body1">Rule Name *</Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <TcTextField
                          name="name"
                          rules={{
                            required: 'Rule Name is required',
                            pattern: {
                              value: constants.NAME_REGEX,
                              message: 'Invalid name',
                            },
                            validate: {
                              nameExists: (value: string) =>
                                isNameExists(value)
                                  ? 'This rule name is already used'
                                  : true,
                            },
                          }}></TcTextField>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={1}>
                    <Button
                      size="small"
                      type="submit"
                      disabled={!isValid || Object.keys(errors).length > 0}
                      onClick={onUpdate}
                      fullWidth
                      variant="contained"
                      color="info">
                      {'Save'}
                    </Button>
                  </Grid>
                  <Grid item xs={1}>
                    <Button
                      size="small"
                      type="button"
                      fullWidth
                      variant="outlined"
                      color="info"
                      onClick={onClose}>
                      {'Cancel'}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Card
                      sx={{
                        backgroundColor: 'secondary.main',
                        width: '100%',
                        height: '100%',
                      }}
                      elevation={0}>
                      <CardContent>
                        <Grid container spacing={2} flexDirection={'column'}>
                          <Grid item alignSelf={'center'}>
                            <strong>
                              <label htmlFor="userGroup">User Group</label>
                            </strong>
                            {process.env.REACT_APP_SHOW_INFO_TOOLTIP?.toLowerCase() ===
                              'yes' && (
                              <TcTooltip title={constants.RULE_USER_GROUP_INFO}>
                                <IconButton size="small" color="primary">
                                  <InfoOutlinedIcon fontSize="inherit" />
                                </IconButton>
                              </TcTooltip>
                            )}
                          </Grid>
                          <Grid item>
                            <Card elevation={0}>
                              <TcAutocomplete
                                name="userGroupId"
                                label=""
                                optionKey="groupId"
                                options={formMasterData.userGroups}
                                value={selectedUserGroup}
                                getOptionLabel={(grp) => grp.name}
                                onValueSelect={onSelectUserGroup}
                                rules={{
                                  required: 'User Group is required',
                                }}></TcAutocomplete>
                            </Card>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={3}>
                    <Card
                      sx={{
                        backgroundColor: 'secondary.main',
                        width: '100%',
                        height: '100%',
                      }}
                      elevation={0}>
                      <CardContent>
                        <Grid container spacing={2} flexDirection={'column'}>
                          <Grid item alignSelf={'center'}>
                            <strong>
                              <label htmlFor="deviceGroup">Device Group</label>
                            </strong>
                            {process.env.REACT_APP_SHOW_INFO_TOOLTIP?.toLowerCase() ===
                              'yes' && (
                              <TcTooltip
                                title={constants.RULE_DEVICE_GROUP_INFO}>
                                <IconButton size="small" color="primary">
                                  <InfoOutlinedIcon fontSize="inherit" />
                                </IconButton>
                              </TcTooltip>
                            )}
                          </Grid>
                          <Grid item>
                            <Card elevation={0}>
                              <TcAutocomplete
                                name="deviceGroupId"
                                label=""
                                optionKey="groupId"
                                options={formMasterData.deviceGroups}
                                value={selectedDeviceGroup}
                                getOptionLabel={(grp) => grp.name}
                                onValueSelect={onSelectDeviceGroup}
                                rules={{
                                  required: 'Device Group is required',
                                }}></TcAutocomplete>
                            </Card>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={3}>
                    <Card
                      sx={{
                        backgroundColor: 'secondary.main',
                        width: '100%',
                        height: '100%',
                      }}
                      elevation={0}>
                      <CardContent>
                        <Grid container spacing={2} flexDirection={'column'}>
                          <Grid item alignSelf={'center'}>
                            <strong>
                              <label htmlFor="localAccess">
                                Local Access Profile
                              </label>
                            </strong>
                          </Grid>
                          <Grid item>
                            <Card elevation={0}>
                              <TcSelectWithStringArray
                                label=""
                                disabled={
                                  !(
                                    selectedDeviceGroup.groupId !== '' &&
                                    selectedUserGroup.groupId !== ''
                                  )
                                }
                                name="accessProfile"
                                options={
                                  constants.CONNECTION_PROFILE_ACCESS_PROFILES
                                }
                                rules={{
                                  required: 'Local Access Profile is required',
                                }}
                              />
                            </Card>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={3}>
                    <Card
                      sx={{
                        backgroundColor: 'secondary.main',
                        width: '100%',
                        height: '100%',
                      }}
                      elevation={0}>
                      <CardContent>
                        <Grid container spacing={2} flexDirection={'column'}>
                          <Grid item alignSelf={'center'} xs={12}>
                            <strong>
                              <label htmlFor="accessWindow">
                                Access Window
                              </label>
                            </strong>
                          </Grid>
                          <Grid item xs={12} sx={{ width: '100%' }}>
                            <Grid
                              container
                              spacing={2}
                              flexDirection={'column'}>
                              {accessWindow ? (
                                <Grid xs={12} item sx={{ width: '100%' }}>
                                  <Card elevation={0}>
                                    <CardActionArea
                                      onClick={() =>
                                        setOpenCalendarModal(true)
                                      }>
                                      <CardContent style={{ padding: 8 }}>
                                        {' '}
                                        <div data-testid="accessWindowButton">
                                          {splitByBreakTag(accessWindow)}
                                        </div>
                                      </CardContent>
                                    </CardActionArea>
                                  </Card>
                                </Grid>
                              ) : (
                                <Grid item xs={12} sx={{ width: '100%' }}>
                                  <Card elevation={0}>
                                    {' '}
                                    <CardContent style={{ padding: 0 }}>
                                      <IconButton
                                        sx={{ paddingLeft: '50%' }}
                                        onClick={() =>
                                          setOpenCalendarModal(true)
                                        }>
                                        <EditCalendarIcon />
                                      </IconButton>
                                    </CardContent>
                                  </Card>
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {openCalendarModal && (
              <RuleScheduleForm
                open={openCalendarModal}
                onCancelCalendarModal={onCancelCalendarModal}
                onAcceptCalendar={onAcceptCalendar}></RuleScheduleForm>
            )}
          </form>
        </FormProvider>
      </Card>
    </React.Fragment>
  );
};

export default AddRule;
