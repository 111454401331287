import React, { useState } from 'react';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import {
  Box,
  Button,
  CardHeader,
  CssBaseline,
  Grid,
  Typography,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import TcTextField from 'common/components/TcTextField';
import '../../common/styles/filter.css';
import TcAutocompleteMultiSelect from 'common/components/TcAutocompleteMultiSelect';
import {
  EventType,
  LogsFilterData,
  LogsFilterMasterData,
  Severity,
} from './types';
import { Site } from 'pages/sites/types';
import { User } from 'pages/users/types';
import dayjs from 'dayjs';

type FilterProps = {
  onApplyFilter: (formData: any) => void;
  handleClearFilter: () => void;
  handleCancelFilter: () => void;
  filters: LogsFilterData;
  logsFilterMasterData: LogsFilterMasterData;
};

const LogsFilter: React.FC<FilterProps> = ({
  filters,
  handleCancelFilter,
  handleClearFilter,
  logsFilterMasterData,
  onApplyFilter,
}) => {
  const { eventTypes, severity, sites, users } = logsFilterMasterData;

  const [selectedSites, setSelectedSites] = useState<string[]>(filters.siteIds);
  const [selectedUsers, setSelectedUsers] = useState<string[]>(filters.userIds);

  const [selectedEventTypes, setSelectedEventTypes] = useState<string[]>(
    filters.eventTypes,
  );
  const [selectedSeverity, setSelectedSeverity] = useState<string[]>(
    filters.severity,
  );

  const methods = useForm({
    defaultValues: filters,
  });

  const { handleSubmit, setValue, reset, control } = methods;

  const onSubmit = handleSubmit(async (data: any) => {
    const endDate = data.endDate ? dayjs(data.endDate).endOf('day') : undefined;
    onApplyFilter({ ...data, endDate });
  });

  const onSiteSelect = (
    _event: React.SyntheticEvent<Element, Event>,
    value: Site[],
  ) => {
    const selectedIds = value.map((site) => site.siteId);
    setValue('siteIds', selectedIds);
    setSelectedSites(selectedIds);
  };

  const onUserSelect = (
    _event: React.SyntheticEvent<Element, Event>,
    value: User[],
  ) => {
    const selectedIds = value.map((user) => user.userId);
    setValue('userIds', selectedIds);
    setSelectedUsers(selectedIds);
  };

  const onEventTypeSelect = (
    _event: React.SyntheticEvent<Element, Event>,
    value: EventType[],
  ) => {
    const selectedIds = value.map((s) => s.value);
    setValue('eventTypes', selectedIds);
    setSelectedEventTypes(selectedIds);
  };

  const onSeveritySelect = (
    _event: React.SyntheticEvent<Element, Event>,
    value: Severity[],
  ) => {
    const selectedIds = value.map((s) => s.value);
    setValue('severity', selectedIds);
    setSelectedSeverity(selectedIds);
  };

  const resetForm = () => {
    reset({
      endDate: null,
      eventTypes: [],
      severity: [],
      userIp: '',
      siteIds: [],
      startDate: null,
      userIds: [],
    });
    setSelectedEventTypes([]);
    setSelectedSeverity([]);
    setSelectedSites([]);
    setSelectedUsers([]);
  };

  const onClear = () => {
    resetForm();
    handleClearFilter();
  };

  const onCancel = () => {
    resetForm();
    handleCancelFilter();
  };

  React.useEffect(() => {
    reset(filters);
  }, [filters, reset]);

  return (
    <React.Fragment>
      <CssBaseline />
      <FormProvider {...methods}>
        <Box>
          <CardHeader
            subheader={
              <Typography variant="subtitle1">
                <strong>Filters</strong>
              </Typography>
            }
            sx={{ paddingTop: 0, paddingLeft: 0 }}
          />
          <Grid container spacing={1}>
            <Grid container spacing={1} item xs={7} md={7}>
              {/* Event Type */}
              <Grid item xs={3} md={3}>
                <TcAutocompleteMultiSelect
                  name="eventTypes"
                  value={
                    selectedEventTypes.map((sev) =>
                      eventTypes.find((s) => s.value === sev),
                    ) as EventType[]
                  }
                  label="Event Types"
                  onValueSelect={onEventTypeSelect}
                  optionKey="value"
                  getOptionLabel={(s) => s.value}
                  options={eventTypes}
                />
              </Grid>

              {/* Severity */}
              <Grid item xs={3} md={3}>
                <TcAutocompleteMultiSelect
                  name="severity"
                  value={
                    selectedSeverity.map((sev) =>
                      severity.find((s) => s.value === sev),
                    ) as Severity[]
                  }
                  label="Severity"
                  onValueSelect={onSeveritySelect}
                  optionKey="value"
                  getOptionLabel={(s) => s.value}
                  options={severity}
                />
              </Grid>

              {/* User */}
              <Grid item xs={3} md={3}>
                <TcAutocompleteMultiSelect
                  name="userIds"
                  value={
                    selectedUsers.map((user) =>
                      users.find((u) => u.userId === user),
                    ) as User[]
                  }
                  label="User"
                  onValueSelect={onUserSelect}
                  optionKey="userId"
                  getOptionLabel={(user) =>
                    `${user.firstName} ${user.lastName}`
                  }
                  options={users}
                />
              </Grid>

              {/* Site */}
              <Grid item xs={3} md={3}>
                <TcAutocompleteMultiSelect
                  name="siteIds"
                  value={
                    selectedSites.map((site) =>
                      sites.find((s) => s.siteId === site),
                    ) as Site[]
                  }
                  label="Site"
                  onValueSelect={onSiteSelect}
                  optionKey="siteId"
                  getOptionLabel={(site) => site.name}
                  options={sites}
                />
              </Grid>
            </Grid>
            {/* IP Address */}
            <Grid container spacing={1} item xs={5} md={5}>
              <Grid item xs={4} md={4}>
                <TcTextField name="userIp" label="IP Address" />
              </Grid>

              {/* Date Range */}
              <Grid item xs={4} md={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Controller
                    name={'startDate'}
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        sx={{
                          '& .MuiInputBase-root': {
                            height: '40px',
                          },
                          '& .MuiOutlinedInput-root': {
                            height: '40px',
                          },
                          '& .MuiInputLabel-root': {
                            lineHeight: '0.9em',
                          },
                        }}
                        {...field}
                        label="Start Date"
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={4} md={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Controller
                    name={'endDate'}
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        sx={{
                          '& .MuiInputBase-root': {
                            height: '40px',
                          },
                          '& .MuiOutlinedInput-root': {
                            height: '40px',
                          },
                          '& .MuiInputLabel-root': {
                            lineHeight: '0.9em',
                          },
                        }}
                        {...field}
                        label="End Date"
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </FormProvider>
      <Grid container spacing={2} sx={{ paddingTop: 2, paddingBottom: 1 }}>
        <Grid item xs={2}>
          <Button
            size="small"
            type="submit"
            onClick={onSubmit}
            fullWidth
            variant="contained"
            color="info">
            {'Apply All'}
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Button
            size="small"
            fullWidth
            variant="outlined"
            color="info"
            onClick={onClear}>
            {'Clear All'}
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Button
            size="small"
            fullWidth
            variant="outlined"
            color="info"
            onClick={onCancel}>
            {'Cancel'}
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default LogsFilter;
