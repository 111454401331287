import React from 'react';

import { CssBaseline, Button, Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import apiClient from 'common/apiClientAxios';
import { setLoader, setSnackbarToast } from 'redux/UiStateSlice';
import TcTextField from 'common/components/TcTextField';
import { useDrawerFormStyles } from 'common/styles/useDrawerFormStyles';
import { constants } from 'common/constants';
import FormHeader from 'common/components/FormHeader';
import { Role } from 'pages/settings/types';
import { defaultPermissions, defaultRoutePermissions } from './Roles';
import AccessSettings from './AccessSettings';
import MenuSettings from './MenuSettings';

type AddRoleProps = {
  onClose: () => void;
  onSaveUpdateTable: () => void;
};

const AddRole: React.FC<AddRoleProps> = (props) => {
  const classes = useDrawerFormStyles();
  const dispatch = useDispatch();
  const onClose = () => {
    props.onClose();
  };

  const defaultValues: Role = {
    name: '',
    description: '',
    roleId: '',
    permissions: defaultPermissions,
    routePermissions: defaultRoutePermissions,
  };

  const methods = useForm<Role>({
    defaultValues,
    mode: 'onBlur',
  });

  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  const onUpdate = handleSubmit(async (data: Role) => {
    try {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: true,
        }),
      );
      const trustRolesResponse = await apiClient.post(`/roles`, data);
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        }),
      );
      dispatch(
        setSnackbarToast({
          message: trustRolesResponse.data.meta.message,
          open: true,
          severity: 'success',
        }),
      );
    } catch (error: any) {
      dispatch(
        setLoader({
          loaderMessage: constants.LOADER_MESSAGE_PLEASE_WAIT,
          openLoader: false,
        }),
      );
      const errorData =
        error.response?.data?.meta?.message || String(error.message);
      dispatch(
        setSnackbarToast({
          message: errorData,
          open: true,
          severity: 'error',
        }),
      );
    }
    props.onSaveUpdateTable();
  });

  const [expanded, setExpanded] = React.useState<string | false>(
    'accessSettingsPanel',
  );

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <React.Fragment>
      <CssBaseline />
      <FormHeader title={`Add Role`} onClose={onClose}></FormHeader>
      <FormProvider {...methods}>
        <form noValidate>
          <Grid container spacing={1} className={classes.formContainer}>
            <Grid item xs={12}>
              <TcTextField
                name="name"
                label="Name *"
                rules={{
                  required: 'Name is required',
                  pattern: {
                    value: constants.ROLE_NAME_REGEX,
                    message: 'Invalid name',
                  },
                }}></TcTextField>
            </Grid>
            <Grid item xs={12}>
              <TcTextField
                name="description"
                label="Description"
                multiline
                rows={2}></TcTextField>
            </Grid>
            <Grid item xs={12}>
              <Accordion
                expanded={expanded === 'accessSettingsPanel'}
                onChange={handleChange('accessSettingsPanel')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Access settings</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <AccessSettings
                    permissions={defaultValues.permissions}></AccessSettings>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12}>
              <Accordion
                expanded={expanded === 'menuSettingsPanel'}
                onChange={handleChange('menuSettingsPanel')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Menus Access settings</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <MenuSettings
                    permissions={defaultRoutePermissions}></MenuSettings>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.bottomDivider}>
            <Grid item xs={2}>
              <Button
                size="small"
                type="submit"
                disabled={!isValid}
                onClick={onUpdate}
                fullWidth
                variant="contained"
                color="info">
                {'Save'}
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                size="small"
                fullWidth
                variant="outlined"
                color="info"
                onClick={onClose}>
                {'Cancel'}
              </Button>
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
        </form>
      </FormProvider>
    </React.Fragment>
  );
};

export default AddRole;
