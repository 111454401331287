import { PublicClientApplication, Configuration } from '@azure/msal-browser';
import apiClient from 'common/apiClientAxios';
import {
  FeatureStatus,
  IdentityAccessProvider,
  IdentityProviderStatus,
} from 'common/enums';
import { IdentityProvider, LoginSetting } from './types';

export async function fetchMsalConfig(): Promise<Configuration> {
  let msalConfig: Configuration = { auth: { clientId: '' } };
  const api = `/settings/identity-providers?status=${IdentityProviderStatus.ACTIVE}`;
  const settingsResponse = await apiClient.get(api);
  const settings = settingsResponse.data.data as IdentityProvider[];
  const azureSettings = settings.find(
    (setting) =>
      setting.accessProvider === IdentityAccessProvider.AZURE &&
      setting.status === IdentityProviderStatus.ACTIVE,
  );
  if (azureSettings)
    msalConfig = {
      auth: {
        clientId: azureSettings.clientId,
        authority: `https://login.microsoftonline.com/${azureSettings.tenantId}`,
        redirectUri: azureSettings.redirectUri,
        postLogoutRedirectUri:
          azureSettings.logoutRedirectUri ?? `${window.location.origin}/login`,
      },
      cache: {
        cacheLocation: 'localStorage', // Store tokens in localStorage (or 'sessionStorage' for session storage)
        storeAuthStateInCookie: false,
      },
    };
  return msalConfig;
}

let msalInstance: PublicClientApplication | null = null;
export async function initializeMsalInstance(): Promise<PublicClientApplication> {
  if (!msalInstance) {
    const msalConfig = await fetchMsalConfig();
    msalInstance = new PublicClientApplication(msalConfig);
    await msalInstance.initialize();
  }
  if (!msalInstance) {
    throw new Error('MSAL instance not initialized.');
  }
  return msalInstance;
}

export async function fetchLoginSettings(): Promise<string[]> {
  const settingsResponse = await apiClient.get('auth/loginFeatures');
  const settings = settingsResponse.data.data as LoginSetting[];
  return settings.map(
    (login) =>
      login.status.toLowerCase() === FeatureStatus.ENABLED.toLowerCase() &&
      login.loginWith.toLowerCase(),
  ) as [];
}
